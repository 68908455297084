import { combineReducers } from 'redux';

import dataReducer from './dataReducer';
import {  i18nReducer } from 'react-redux-i18n';

const rootReducer = combineReducers({
  data: dataReducer,
  i18n: i18nReducer
});

export default rootReducer;
