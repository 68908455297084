import React from "react";
import { useSelector  } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../shared/styles/Colors';
import Fonts from '../shared/styles/Fonts';
import styles from '../shared/styles/BottomFrameStylesheet';

import { Tongue } from "../shared/styles/elements/img_tongue";
import ImgUE from "../../assets/png/img_ue.png";
import ImgLogo from "../../assets/svg/img_logo_franciszkanie.svg";
import { getCloisersQR } from '../../utils';

const useStyles = makeStyles(() => (styles()));

const BottomFrameWithUrl = () => {
  const classes = useStyles({ 
    colors: Colors(),
    fonts: Fonts(),
  });

  const langId = useSelector(state => state.i18n.locale);
  const data = useSelector(state => (state.data["lang"+langId]));
  const qrCode = getCloisersQR(data);

  return(
    <div className={classes.bottomFrame}>
      <Tongue className={classes.tongue} />
      <div className={classes.domenaUrl}>franciszkanska.pl</div>
      <img className={classes.imgLogo} src={ImgLogo} alt=""/>
      <img className={classes.imgUe} src={ImgUE} alt=""/>
      <img className={classes.imgQR} src={qrCode} alt=""/>
    </div>
  )
}
export default BottomFrameWithUrl;