import React from "react";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import modalStylesheet from './styles/modalStylesheet';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../shared/styles/Colors';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import muiModal from '../shared/muiStyles/modal';
import CloseRounded from '@material-ui/icons/CloseRounded';

var Spinner = require('react-spinkit');

const useStyles = makeStyles(() => modalStylesheet());

const Book = (props) => {
  const classes = useStyles({ colors: Colors() });
  const { iframeLoad, setIframeLoad } = props;

  const modalTheme = createMuiTheme(muiModal({ 
    primary: Colors().primary,
    secondary: Colors().secondary,
  }));

  return(
    <MuiThemeProvider theme={modalTheme}>
      <Modal
        className={classes.modal}
        open={props.open}
        onClose={() => {
          setIframeLoad(false)
          props.handleClose()
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade 
          in={props.open}
          timeout={{
            enter: 1000,
            exit: 1000,
          }}
        >
          <div className={classes.paper}>
            {iframeLoad && <Spinner className={classes.spinner} name="ball-spin-fade-loader" />}
            <div className={classes.topCover}></div>
            <div className={classes.topEdgeCover}></div>
            <div className={classes.bottomEdgeCover}></div>
            <div className={classes.bottomCover}></div>
            <div className={classes.rightScrollbarCover}></div>
            <div className={classes.rightMenuCover}></div>
            <div className={classes.topIconsCover}></div>
            <div className={classes.rightIconsCover}></div>
            <div className={classes.closeContainer} onClick={props.handleClose}>
              <CloseRounded className={classes.close} />
            </div>
            <iframe 
              className={classes.modalIframe}
              title="book"
              src={props.bookUrl}
              onLoad={() => setIframeLoad(false)}
            ></iframe>
          </div>
        </Fade>
      </Modal>
    </MuiThemeProvider>
  )
}
export default Book;