const MuiModal = (props) => {
  return {
    overrides: {
      MuiBackdrop: {
        root: {
          backgroundColor: 'transparent',
        }
      },
      MuiButton: {
        root: {
          // lineHeight: '1',
        },
        text: {
          padding: '0',
        }
      }
    },
    palette: {
      primary: {
        main: `${props.primary}`,
      },
      secondary: {
        main: `${props.secondary}`,
      },
    },
  }
}
export default MuiModal;