import React from "react";
import { Translate } from "react-redux-i18n";
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../../shared/styles/Colors';

import styles from '../../shared/styles/categoryDescriptionStylesheet'
import TextContainer from './TextContainer';

const useStyles = makeStyles((props) => (styles(props)));

const CategoryDescription = () => {
  const colors = Colors();
  const classes = useStyles({ colors });
  return (
  <div className={classes.categoryDescription}>
    <div className={classes.mainTitle}><Translate value="basilica.title" /></div>
      <TextContainer classes={classes} />
    <div className={classes.bottomTextShadow}></div>
  </div>
  )
}
export default CategoryDescription;