import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { I18n } from "react-redux-i18n";
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles/homeNavStylesheet';
import Colors from '../shared/styles/Colors';
import Fonts from '../shared/styles/Fonts'
import { CornerLines } from '../shared/styles/elements/img_corner_lines';

const useStyles = makeStyles(() => styles());

const NavElement = (props) => {
  const visualFacs = useSelector(state => state.data.visualFacilities);
  const name = (props) => I18n.t(props);
  const classes = useStyles({ 
    colors: Colors(), 
    contrast: visualFacs,
    initialFont: Fonts().initial,
  });
  return(
    <div className={classes.navlinkContainer}>
      <NavLink className={classes.link} to={props.url}>
      <div className={classes.initialLetter}>{name(props.name_1).substr(0,1)}</div>
      <div className={classes.navlinkText}>
        <CornerLines className={classes.cornerBackground} />
        <div className={classes.navlinkLine}>{name(props.name_1).substr(1)}</div>
        <div className={classes.navlinkLine}>{name(props.name_2)}</div>
      </div>
      </NavLink>
    </div>
  )
}

const HomeNav = () => {
  const classes = useStyles({ colors: Colors() });
  return(
    <div className={classes.mainMenu}>
      <NavElement 
        url="/book-collection"
        initial="home.book_coll_0"
        name_1="home.book_coll_1"
        name_2="home.book_coll_2" />
      <NavElement 
        url="/galleries" 
        initial="home.galleries_0"
        name_1="home.galleries_1"
        name_2="" />
    </div>
  )
}
export default HomeNav;