const frameStylesheet = () => {
  return {
    bgBody: props => ({
      backgroundColor: `${props.colors.background}`,
      height: '100vh',
      width: '100vw',
      transition: '0.5s background-color',
      transitionDelay: '0.2s',
    }),
    topFrame: {
      height: '2.5vmax',
      width: 'calc(100% - 46.25vmax)', // 5vmax per language
      minWidth: '30vw',
      backgroundColor: 'white',
      borderRadius: '0 0 2.5vmax 0',
    },
    middleRow: {
      position: 'relative',
      display: 'flex',
      height: 'calc(100% - 10.625vmax)',
    },
    leftFrame: {
      width: '2.5vmax',
      backgroundColor: 'white',
    },
    screenContent: {
      position: 'relative',
      width: 'calc(100% - 5vmax)',
    },
    rightFrame: {
      position: 'absolute',
      right: '0',
      bottom: '0',
      width: '2.5vmax',
      height: 'calc(100% - 8.75vmax)',
      backgroundColor: 'white',
      borderRadius: '2.5vmax 0 0 0',
    }
  }
}

export default frameStylesheet;