const homeNavStylesheet = (props) => {
  return {
    mainMenu: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      alignItems: 'center',
      width: '100%',
    },
    navlinkContainer: {
      float: 'left',
      width: '27vmax',
      height: '13.3vmax',
      marginLeft: '0.625vmax',
      marginRight: '0.625vmax',
      zIndex: '100',
    },
    link: {
      display: 'flex',
      justifyContent: 'center',
    },
    initialLetter: props => ({
      fontSize: '9.375vmax',
      color: `${props.colors.font}`,
      fontFamily: `${props.initialFont}`,
      transition: '0.5s color',
    }),
    navlinkText: props => ({
      height: '10vmax',
      marginTop: '1.67vmax',
      paddingTop: '1.875vmax',
      paddingRight: '1.875vmax',
      textAlign: 'left',
      color: 'white',
      position: 'relative',
    }),
    cornerBackground: props => ({
      position: 'absolute',
      width: '7.08vmax',
      right: '0',
      top: '0',
      fill: `${props.colors.font}`,
      transition: 'fill 0.5s',
    }),
    navlinkLine: {
      fontFamily: 'cormorant-garamond-bold',
      fontSize: '2.92vmax',
    },
  }
}
export default homeNavStylesheet;