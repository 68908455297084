import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Frame from "../shared/Frame";
import BottomFrameWithReturn from "../shared/BottomFrameWithReturn";
import ScreenContent from "./ScreenContent";

import { addGalleriesEntry } from "../../store/actions/dataActions";

const Galleries = () => {
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(addGalleriesEntry());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <Frame mainClass="galleries" screenContent={<ScreenContent />} bottomFrame={<BottomFrameWithReturn />} />
  )
}

export default Galleries;