const categoryObjectsStylesheet = (props) => {
  return(
    {
      categoryList: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        overflow: 'auto',
        marginTop: '8.33vmin',
        textAlign: 'center',
        paddingTop: '0',
        paddingBottom: '0',
        '&::-webkit-scrollbar': {
          width: '0px !important',
        },
      },
      objectsList: {
        display: 'flex',
        flexDirection: 'column',
        padding: '6.67vmin 3.95% 6.67vmin 4.33%',
        width: '41.72%',
        position: 'relative',
      },
      tabEnd: {
        width: '3vmin',
        height: '100%',
      },
      tabRightShadow: props=> ({
        position: 'absolute',
        right: '6.67vmin',
        top: '13.33vmin',
        bottom: '15vmin',
        boxShadow: `0 4vmin 2vmin 4vmin ${props.colors.background}`,
      }),
      tabsLeftShadow: props=> ({
        position: 'absolute',
        left: '7.31vmin',
        top: '13.33vmin',
        bottom: '15vmin',
        boxShadow: `0 3vmin 1vmin 1.2vmin ${props.colors.background}`,
        zIndex: '100',
      }),
    } 
  )
}
export default categoryObjectsStylesheet;