import React from "react";

import { makeStyles } from '@material-ui/core/styles';
import Colors from '../shared/styles/Colors';
import styles from './styles/FrameStylesheet';
import './styles/transition.scss';

const useStyles = makeStyles(() => (styles()));

const Frame = (props) => {
  const classes = useStyles({ colors: Colors() });
  return (
    <div className={props.mainClass}>
          <div className={classes.bgBody}>
            <div className={classes.topFrame}></div>
            <div className={classes.middleRow}>
              <div className={classes.leftFrame}></div>
              {props.screenContent}
              <div className={classes.rightFrame}></div>
            </div>
            {props.bottomFrame}
          </div>
    </div>
  )
}
export default Frame;