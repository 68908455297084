import React from "react";
import Frame from "../shared/Frame";
import ScreenContent from "./ScreenContent";
import BottomFrameWithUrl from "./BottomFrameWithUrl";

const Home = () => {
  return (
    <Frame mainClass="homePage" screenContent={<ScreenContent />} bottomFrame={<BottomFrameWithUrl />} />
  )
}

export default Home;