/**
 |--------------------------------------------------
 | SCREENS ACTIONS
 |--------------------------------------------------
 */

  export const REQUEST_DATA = 'REQUEST_DATA';
  export const RESPONSE_DATA = 'RESPONSE_DATA';
  export const CLEAR_DATA = 'CLEAR_DATA';
  export const SWITCH_VISUAL_FACILITIES = 'SWITCH_VISUAL_FACILITIES';
  export const REGISTER_KIOSK = 'REGISTER_KIOSK';
  export const ADD_MODAL_ENTRY = 'ADD_MODAL_ENTRY';
  export const ADD_GALLERIES_ENTRY = 'ADD_GALLERIES_ENTRY';
  export const ADD_BOOK_COLLECTIONS_ENTRY = 'ADD_BOOK_COLLECTIONS_ENTRY';