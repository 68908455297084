import { get } from 'lodash';
import thumbnails from '../config/offline/v3/thumbnails';

export const filterCategoryData = (data, id) => {
  const filteredData = data ? data.filter(x => x.id === `${id}`)[0] : []
  return filteredData;
}

export const getCloisersQR = (data) => {
  return get(filterCategoryData(data, 93),"qrCode","");
}

export const getCategoryGallery = (data, id) => {
  const thumbnailKeys = Object.keys(thumbnails)
  
  const allImgs = get(filterCategoryData(data, id),"gallery",[])
  const gallery = allImgs.filter((x,i) => i%2 === 0).map((obj,i) => {
    const sm_index = 2*i + 1;
    return(
      {
        ...obj,
        image_sm: (thumbnailKeys.includes(obj.image)) ? thumbnails[obj.image] : allImgs[sm_index].image
      }
    )
  });
  return gallery;
}