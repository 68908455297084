import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import Colors from '../styles/Colors';
import muiModal from '../muiStyles/modal';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import modalStylesheet from './modalStylesheet';
import { ObjectTile } from './ObjectTile/ObjectTile';
import { ModalPaperLite } from './ModalPaper/ModalPaperLite'

import { addModalEntry } from "../../../store/actions/dataActions";

const useStyles = makeStyles((props) => modalStylesheet(props));

export default function TransitionsModal(props) {
  const classes = useStyles({ colors: Colors() });
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    dispatch(addModalEntry());
    setTimeout(() => { setOpen(true) }, 250);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const modalTheme = createMuiTheme(muiModal({ 
    primary: Colors().primary,
    secondary: Colors().secondary,
  }));

  return (
    <MuiThemeProvider theme={modalTheme}>
      <div className={classes.singleObject}>
        <ObjectTile classes={classes} handleOpen={handleOpen} object={props.object} />
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade 
            in={open}
            timeout={{
              enter: 500,
              exit: 500,
            }}
          >
            <div className={classes.paper}>
              <ModalPaperLite
                classes={classes}
                object={props.object}
                handleClose={handleClose}
                Colors={Colors()}
              />
            </div>
          </Fade>
        </Modal>
      </div>
    </MuiThemeProvider>
  );
}