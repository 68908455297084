import React from 'react';
import { useSelector  } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';

import Colors from '../shared/styles/Colors';
import ModalLite from "../shared/modal/ModalLite";
import { basilicaId } from '../../config/apiCategoryId';
import styles from './styles/categoryObjectsStylesheet';
import { showBasilicaObjects } from '../../config/options';

const useStyles = makeStyles((props) => (styles(props)));

export default function CategoryObjects(props) {
  const langId = useSelector(state => state.i18n.locale);
  const selectData = useSelector(state => (state.data["lang"+langId]));
  
  const basilicaData = (showBasilicaObjects)?(selectData&&selectData.filter(x => x.categories.find(x => x.id===basilicaId))):(selectData);

  const colors = Colors();
  const classes = useStyles({ colors });

  return(
    <div className={classes.objectsList}>
      <div className={classes.tabsLeftShadow}></div>
      <div className={classes.categoryList}>
        
        {basilicaData&&basilicaData.map((object) => (
          <ModalLite key={object.id} object={object} />))}

        {((basilicaData&&Object.keys(basilicaData).length)>6)?(
          <><div className={classes.tabEnd}></div>
          <div className={classes.tabRightShadow}></div></>
        ):(<div></div>) }
        
      </div>
    </div>
  );
}