import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../shared/styles/Colors';
import styles from './styles/categoryObjectsStylesheet';
import { get } from 'lodash';
import ModalLite from "../shared/modal/ModalLite";
import thumbnails from '../../config/offline/v3/thumbnails'

const useStyles = makeStyles((props) => (styles(props)));

export default function CategoryObjects(props) {
  const colors = Colors();
  const classes = useStyles({ colors });

  const thumbnailKeys = Object.keys(thumbnails)
  const data = get(props,"data.gallery",[])
  const gallery = data.filter((x,i) => i%2 === 0).map((obj,i) => {
    const sm_index = 2*i + 1;
    return(
      {
        ...obj,
        image_sm: (thumbnailKeys.includes(obj.image)) ? thumbnails[obj.image] : data[sm_index].image
      }
    )
  });

  return(
    <div className={classes.categoryList}>

      {Array.isArray(gallery) && gallery.map((object) => (
        <ModalLite key={object.id} object={object} />))}

      {((gallery&&gallery.length)>6)?(
        <><div className={classes.tabEnd}></div>
        <div className={classes.tabRightShadow}></div></>
      ):(<div></div>) }
    </div>
  );
}