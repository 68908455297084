import React from "react";
import { useSelector  } from "react-redux";
import { NavLink } from "react-router-dom";
import { Translate } from "react-redux-i18n";
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../shared/styles/Colors';
import Fonts from '../shared/styles/Fonts';
import styles from './styles/BottomFrameStylesheet';
import { Tongue } from "./styles/elements/img_tongue";

import ImgUE from "../../assets/png/img_ue.png";
import ImgLogo from "../../assets/svg/img_logo_franciszkanie.svg";
import ArrowBackRounded from '@material-ui/icons/ArrowBackRounded';
import { getCloisersQR } from '../../utils';

const useStyles = makeStyles(() => (styles()));

const BottomFrameWithReturn = () => {
  const classes = useStyles({ 
    colors: Colors(),
    fonts: Fonts(),
  });

  const langId = useSelector(state => state.i18n.locale);
  const data = useSelector(state => (state.data["lang"+langId]));
  const qrCode = getCloisersQR(data);

  return(
    <div className={classes.bottomFrame}>
      <Tongue className={classes.tongue} />
      <NavLink to="/" className={classes.returnButton}>
        <ArrowBackRounded className={classes.returnIcon} /><div className={classes.returnText}><Translate value="return"/></div>
      </NavLink>
      <img className={classes.imgLogo} src={ImgLogo} alt=""/>
      <img className={classes.imgUe} src={ImgUE} alt=""/>
      <img className={classes.imgQR} src={qrCode} alt=""/>
    </div>
  )
}
//<NavLink to="/"></NavLink>
export default BottomFrameWithReturn;