import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { ModalPaperLite } from '../shared/modal/ModalPaper/ModalPaperLite'

const Tile = (props) => {
  const { classes, colors, object, modalClasses } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setTimeout(() => { setOpen(true) }, 250);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <>
      <div className={classes.tileContainer} onClick={handleOpen}>
        <Button color="primary">
          <LazyLoadImage
            className={classes.tile}
            alt=""
            effect="opacity"
            src={props.object.image_sm} 
          />
        </Button>
      </div>
      <Modal
        className={modalClasses.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade 
          in={open}
          timeout={{
            enter: 500,
            exit: 500,
          }}
        >
          <div className={modalClasses.paper}>
            <ModalPaperLite
              classes={modalClasses}
              object={object}
              handleClose={handleClose}
              Colors={colors}
            />
          </div>
        </Fade>
      </Modal>
    </>
  )
}
export default Tile;