import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CloseRounded from '@material-ui/icons/CloseRounded';
import { ObjectImage } from './ObjectImage/ObjectImage';
import { modalPaperStylesheet} from './modalPaperStylesheet';
import ModalBg from '../../../../assets/png/bg_modal.png';

const useStyles = makeStyles(() => modalPaperStylesheet());

export const ModalPaperLite = (props) => {

  const classes = useStyles({ colors: props.Colors });
  const handleClose = props.handleClose;

  return(
    <>
      <div className={classes.closeContainer} onClick={handleClose}>
        <CloseRounded className={classes.close} />
      </div>
      <ObjectImage classes={classes} src={props.object.image} src_sm={props.object.image_sm} />
      <img src={ModalBg} alt="" style={{ 
        position: 'absolute', 
        top: '0',
        left: '0',
        opacity: '1',
        height: '100%',
        zIndex: '0',
        }} />
    </>
  )
}