import React from "react";
import { useSelector  } from "react-redux";
import { Translate } from "react-redux-i18n";
import { makeStyles } from '@material-ui/core/styles';
import Colors from '../shared/styles/Colors';
import styles from '../shared/styles/categoryDescriptionStylesheet';
import modalStylesheet from '../shared/modal/modalStylesheet'
import TilesSlider from './TilesSlider';
import muiModal from '../shared/muiStyles/modal';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { filterCategoryData, getCategoryGallery } from "../../utils";
import MoveSingleChar from '../shared/MoveSingleChar';

const useStyles = makeStyles((props) => (styles(props)));
const useModalStyles = makeStyles((props) => modalStylesheet(props));

const Section = ({
  data = {},
  imgToRight = false,
  hasTitle = true,
  classes = {
    imgToRight: "",
    imgToLeft: "",
    boldedText: ""
  }
}) => {
  const imgClass = imgToRight ? classes.imgToRight : classes.imgToLeft;
  return(
    <>
      {hasTitle && <div className={classes.boldedText}>{data.title}</div>}
      <p>
        <img src={data.image} alt="" className={imgClass} />
        <MoveSingleChar text={data.introduction} />
      </p>
    </>
  )
}

const CategoryDescription = () => {
  const colors = Colors();
  const classes = useStyles({ colors });
  const modalClasses = useModalStyles({ colors });

  const modalTheme = createMuiTheme(muiModal({ 
    primary: Colors().primary,
    secondary: Colors().secondary,
  }));

  const langId = useSelector(state => state.i18n.locale);
  const selectData = useSelector(state => (state.data["lang"+langId]));

  return (
  <MuiThemeProvider theme={modalTheme}>
    <div className={classes.categoryDescription}>
      <div className={classes.mainTitle}><Translate value="galleries.title" /></div>
      <div className={classes.textContainer}>
        <Section classes={classes} data={filterCategoryData(selectData, 93)} hasTitle={false} />

        <Section classes={classes}  data={filterCategoryData(selectData, 90)} imgToRight />
        <TilesSlider 
          classes={classes}
          colors={colors}
          modalClasses={modalClasses} 
          gallery={getCategoryGallery(selectData, 90)}
        />

        <Section classes={classes}  data={filterCategoryData(selectData, 91)} />
        <TilesSlider 
          classes={classes}
          colors={colors}
          modalClasses={modalClasses} 
          gallery={getCategoryGallery(selectData, 91)}
        />

        <Section classes={classes}  data={filterCategoryData(selectData, 92)} imgToRight />
        <TilesSlider 
          classes={classes}
          colors={colors}
          modalClasses={modalClasses} 
          gallery={getCategoryGallery(selectData, 92)}
        />

        <Section classes={classes}  data={filterCategoryData(selectData, 95)} />
        <Section classes={classes}  data={filterCategoryData(selectData, 94)} imgToRight />

      </div>
      <div className={classes.bottomTextShadow}></div>
    </div>
  </MuiThemeProvider>
  )
}
export default CategoryDescription;