import React from 'react';
import { useSelector  } from "react-redux";
import PropTypes from 'prop-types';
import { get } from 'lodash';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';
import CategoryObjects from "./CategoryObjects";
import muiTabs from "./muiStyles/tabs"
import Colors from '../shared/styles/Colors';
import styles from './styles/tabsStylesheet'
import MoveSingleChar from '../shared/MoveSingleChar';

const useStyles = makeStyles((props) => (styles(props)));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function SimpleTabs() {
  const [value, setValue] = React.useState(0);

  const langId = useSelector(state => state.i18n.locale);
  const selectData = useSelector(state => (state.data["lang"+langId]));

  const cloisterGallery = selectData&&selectData.filter(x => x.id === "93")[0];
  const epitaphsGallery = selectData&&selectData.filter(x => x.id === "95")[0];
  const portraitGallery = selectData&&selectData.filter(x => x.id === "94")[0];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabsTheme = createMuiTheme(muiTabs());
  const colors = Colors();
  const classes = useStyles({ colors });

  return (
    <MuiThemeProvider theme={tabsTheme}>
      <div className={classes.objectsList}>
        <div className={classes.tabsLeftShadow}></div>
        <AppBar position="static">
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label={<MoveSingleChar text={get(cloisterGallery,"title") || ""}/>} {...a11yProps(0)} />
            <Tab label={<MoveSingleChar text={get(epitaphsGallery,"title") || ""}/>} {...a11yProps(1)} />
            <Tab label={<MoveSingleChar text={get(portraitGallery,"title") || ""}/>} {...a11yProps(2)} />
          </Tabs>
        </AppBar>       
        <TabPanel value={value} index={0}>
          <CategoryObjects data={cloisterGallery} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CategoryObjects data={epitaphsGallery} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CategoryObjects data={portraitGallery} />
        </TabPanel>
      </div>
    </MuiThemeProvider>
  );
}