const modalStylesheet = (props) => {
  return {
    modal: {
      display: 'flex',
      justifyContent: 'center',
    },
    paper: props => ({
      display: 'flex',
      backgroundColor: `${props.colors.modalBackground}`,
      boxShadow: `0px 0px 1.85vmin 0.46vmin ${props.colors.backgroundShadow}`,
      outline: 'none',
      width: '95vmax',
      marginTop: '4.44vmin',
      marginBottom: '4.44vmin',
      position: 'relative',
    }),
    topCover: props => ({
      position: 'absolute',
      width: '100%',
      height: '80px',
      backgroundColor: `${props.colors.modalBackground}`,
      opacity: '1',
    }),
    bottomCover: props => ({
      position: 'absolute',
      width: '100%',
      height: '6.67vmin',
      backgroundColor: `${props.colors.modalBackground}`,
      opacity: '1',
      bottom: '0',
    }),
    rightScrollbarCover: props => ({
      position: 'absolute',
      height: '91.12vmin',
      width: '6.67vmin',
      backgroundColor: `${props.colors.modalBackground}`,
      opacity: '1',
      right: '0',
    }),
    rightMenuCover: {
      position: 'absolute',
      height: '91.12vmin',
      width: '420px',
      // backgroundColor: 'red',
      // opacity: '0.5',
      right: '6.67vmin',
    },
    topIconsCover: {
      position: 'absolute',
      height: '60px',
      width: '140px',
      // backgroundColor: 'red',
      // opacity: '0.5',
      left: 'calc(50% - 216px)',
      top: '80px',
    },
    rightIconsCover: {
      position: 'absolute',
      height: '60px',
      width: '520px',
      // backgroundColor: 'blue',
      // opacity: '0.5',
      right: '6.67vmin',
      top: '80px',
    },
    topEdgeCover: {
      position: 'absolute',
      height: '15px',
      width: '100%',
      // backgroundColor: 'green',
      // opacity: '0.5',
      top: '80px',
      zIndex: '300',
    },
    bottomEdgeCover: {
      position: 'absolute',
      height: '10px',
      width: '100%',
      // backgroundColor: 'green',
      // opacity: '0.5',
      bottom: '6.67vmin',
      zIndex: '300',
    },
    modalIframe: {
      margin: '0 5.09vmin 4.81vmin 6.67vmin',
      width: '100%',
      border: '0',
    },
    closeContainer: {
      position: 'absolute',
      height:'6.67vmin',
      width:'6.67vmin',
      right: '0',
      top: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    close: props => ({
      color: `${props.colors.modalFont}`,
      fontSize: '4.44vmin',
    }),
    spinner: props => ({
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      color: `${props.colors.font}`,
    }),
  }
}
export default modalStylesheet;