import { apiOnline, internetOnline } from './options';

export const apiServerPath = (langId) => {
  return (apiOnline) ? (`https://proxy.netigen.eu/franciscans?lang=${langId}&lat=52.2366745&long=21.034423`
  ) : ((internetOnline)?(`/apiLocal/v3/lang=${langId}&lat=52.2366745&long=21.034423.json`):(`/apiOffline/v3/lang=${langId}&lat=52.2366745&long=21.034423.json`));
};

export const offlineApi = (langId) => `/apiOffline/v3/lang=${langId}&lat=52.2366745&long=21.034423.json`;

export const publicImagesPath = `/images/`;

export const loginPath = 'https://cms.netigen.eu/dev/register-franciscan-kiosk';