import React from "react";
import { useSelector } from "react-redux";

export const GetCategoryText = (constantTexts, classes, category) => {
  const langId = useSelector(state => state.i18n.locale);
  const output = constantTexts[category].map((object, index) => {
    const { type, content } = object.text;
    switch (type) {
      case "bold": 
        return <div className={classes.boldedText} key={index}>{content[langId]}</div>;
      default: 
        if (object.hasOwnProperty('image')) {
          const { float, url } = object.image;
          switch (float) {
            case "right": 
              return <p key={index}><img src={url} alt="" className={classes.imgToRight} />{content[langId]}</p>;
            default:
              return <p key={index}><img src={url} alt="" className={classes.imgToLeft} />{content[langId]}</p>;
          }
        } else return <p key={index}>{content[langId]}</p>;
    }
  });
  return(output);
}