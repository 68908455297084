export const apiOnline = true; //false when access denied for external devices (tablet/phone without no-cors mode)
export const internetOnline = true; //false means: images of objects are loaded from folder

export const showExampleGallery = false;

//showBasilicaObjects depreciated: no longer "Basilica and Chapels" tile
export const showBasilicaObjects = true; //in "Basilica and Chapels": true - show basilica objects. false - show all from api

export const fetchInterval = 900000;

export const exampleGallery = [
  { image: `/images/GOPR5584.JPG`, id: "530",},
  { image: `/images/GOPR5588.JPG`, id: "531",},
  { image: `/images/GOPR5590.JPG`, id: "532",},
  { image: `/images/GOPR5598.JPG`, id: "533",},
  { image: `/images/GOPR5601.JPG`, id: "534",},
  { image: `/images/GOPR5608.JPG`, id: "535",},
  { image: `/images/IMG_4874.JPG`, id: "536",},
  { image: `/images/IMG_4881.JPG`, id: "537",},
];