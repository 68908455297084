import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import ScreenContent from "./ScreenContent";
import BottomFrameWithReturn from "../shared/BottomFrameWithReturn";
import Frame from "../shared/Frame";

import { addBookCollectionsEntry } from "../../store/actions/dataActions";

const BookCollection = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(addBookCollectionsEntry());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    return (
        <Frame mainClass="bookCollection" screenContent={<ScreenContent />} bottomFrame={<BottomFrameWithReturn />} />
    )
}

export default BookCollection;