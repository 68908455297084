const bottomFrameStylesheet = () => {
  return {
    returnIcon: props => ({
      color: `${props.colors.background}`, 
      fontSize: '4.44vmin',
      bottom: '0',
    }),
    returnButton: props => ({
      position: 'absolute',
      fontFamily: `metropolis-bold`,
      fontSize: '2.96vmin',
      marginTop: '2.81vmax',
      marginLeft: '6.25vmax',
      display: 'flex',
      alignItems: 'center',
    }),
    returnText: props => ({
      color: `${props.colors.background}`,
      fontFamily: `${props.fonts.back}`,
      textDecoration: 'none',
    }),
    bottomFrame: {
      position: 'relative',
      height: '8.125vmax',
      backgroundColor: 'white',
    },
    tongue: props => ({
      position: 'absolute',
      top: '-1px',
      left: '50%',
      transform: 'translate(-50%, 0)',
      height: '10vmin',
      maxHeight: '6vmax',
      fill: `${props.colors.tongue}`,
      transition: 'fill 0.5s',
      transitionDelay: '0.2s',
    }),
    domenaUrl: props => ({
      position: 'absolute',
      fontFamily: 'metropolis-bold',
      fontSize: '2.96vmin',
      color: `${props.colors.background}`,
      transition: 'color 0.5s',
      paddingTop: '3.125vmax',
      paddingLeft: '6.25vmax',
    }),
    imgLogo: {
      position: 'absolute',
      top: '-1.775vmax',
      left: '50%',
      transform: 'translate(-50%, 0)',
      height: '10vmin',
      maxHeight: '6vmax',
      zIndex: '200',
    },
    imgUe: {
      height: '3.23vmax',
      position: 'absolute',
      // right: '6.25vmax',
      right: '12.25vmax',
      top: '50%',
      transform: 'translate(0, -50%)',
      zIndex: '1',
    },
    imgQR: {
      bottom: "53px",
      right: "2.5vmax",
      height: "80px",
      zIndex: "1",
      position: "absolute",
    }
  }
}

export default bottomFrameStylesheet;