const visualImparedStylesheet = (props) => {
  return {
    visuallyImpairedContainer: props => ({
      fontFamily: `${props.fonts.visuallyImpared}`,
      fontSize: '0.94vmax',
      color: 'white',
      textAlign: 'left',
      marginLeft: '1.25vmax',
      marginRight: 'auto',
      padding: '2.5vmax',
      alignItems: 'center',
      position: 'relative',
    }),
    visuallyImpaired: props => ({
      height: '100%',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      borderColor: `${props.colors.border}`,
      borderStyle: 'solid',
      borderWidth: '0px 0px 0.2vmax 0px',
      transition: 'border-color 0.5s',
    }),
    textContainer: {
      float: 'left',
    },
    marginTop: {
      marginTop: '0.16vmax',
    },
    imgEye: {
      height: '2.5vmax',
      marginRight: '1.875vmax',
      float: 'left',
    },
  }
}
export default visualImparedStylesheet;