import React from "react";
import Tile from './Tile';

const TilesSlider = (props) => {
  const { gallery, classes, modalClasses, colors } = props;

  return(
    <div className={classes.tilesContainer}>
      <div className={classes.sliderShadow}></div>      
        <div className={classes.tilesSlider}>
          <>
            {gallery&&gallery.map((object, index) => (
              <Tile 
                key={object.id} 
                index={index} 
                classes={classes} 
                object={object} 
                modalClasses={modalClasses}
                colors={colors}
              />
            ))}
          </>
          <div className={classes.sliderEnd}></div>
        </div>
      <div className={classes.sliderShadow}></div>
    </div>
  )
}
export default TilesSlider;