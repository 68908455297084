const languagesStylesheet = (props) => {
  return {
    languages: {
      marginRight: '2.5vmax',
      marginLeft: 'auto',
      padding: '1.25vmax',
      zIndex: '2',
    },
    flags: {
        display: 'flex',
        height: '5vmax',
        position: 'relative',
    },
    singleFlag: props => ({
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0.625vmax',
      height: '3.25vmax',
      width: '3.25vmax',
      borderWidth: '0.25vmax',
      borderStyle: 'solid',
      borderColor: `${props.colors.borderList}`,
      transition: 'border-color 1s',
    }),
    flagImg: {
      height: '2.5vmax',
      margin: '0.625vmax',
    },
    contrastSelectedLang: props => ({
      top: '0.625vmax',
      left: `${5*props.lang+0.625}vmax`,
      position: 'absolute',
      height: '3.25vmax',
      width: '3.25vmax',
      borderWidth: '0.25vmax',
      borderStyle: 'solid',
      borderColor: `${props.colors.border}`,
    }),
      normalSelectedLang: props => ({
      top: '4.125vmax',
      left: `${5*props.lang+0.625}vmax`,
      position: 'absolute',
      height: '0.25vmax',
      width: '3.75vmax',
      backgroundColor: `${props.colors.font}`,
    }),
  }
}
export default languagesStylesheet;