import React from "react";
import constantTexts from '../../../config/constantTexts';
import { GetCategoryText } from '../../shared/GetCategoryText';

const TextContainer = (props) => {
  const classes = props.classes;
  return(
    <div className={classes.textContainer}>
      {GetCategoryText(constantTexts, classes, "basilica").map(object => object)}
      <div className={classes.bottomEnd}></div>
    </div>
  )
}
export default TextContainer;