import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLocale } from 'react-redux-i18n';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles/languagesStylesheet';
import Colors from '../shared/styles/Colors'

import UK_flag from "../../assets/png/icn_flag_uk.png";
import PL_flag from "../../assets/png/icn_flag_poland.png";
import DE_flag from "../../assets/png/icn_flag_germany.png";
import RU_flag from "../../assets/png/icn_flag_russia.png";
import UA_flag from "../../assets/png/icn_flag_ukraine.png";
import IT_flag from "../../assets/png/icn_flag_italy.png";
import FR_flag from "../../assets/png/icn_flag_france.png";

const useStyles = makeStyles(() => styles());

const LangFlag = (props) => {
  const langId = useSelector(state => state.i18n.locale);
  const dispatch = useDispatch();

  const classes = useStyles({ colors: Colors() });

  return(
    <div className={classes.singleFlag}>
      <img className={classes.flagImg} src={props.src} alt={props.alt} onClick={
        () => {(langId!==props.id)&&(dispatch(setLocale(props.id)))} 
      }/>
    </div>
  )
}

const Languages = () => {
  const langId = useSelector(state => state.i18n.locale);
  const visualFacs = useSelector(state => state.data.visualFacilities);
  const classes = useStyles({ 
    colors: Colors(),
    lang: parseInt(langId),
  });
  return(
    <div className={classes.languages}>
      <div className={classes.flags}>
        <LangFlag id="0" src={UK_flag} alt="English" />
        <LangFlag id="1" src={PL_flag} alt="Polski" />
        <LangFlag id="2" src={FR_flag} alt="Français" />
        <LangFlag id="3" src={DE_flag} alt="Deutsch" />
        <LangFlag id="4" src={IT_flag} alt="Italiana" />
        <LangFlag id="5" src={UA_flag} alt="Український" />
        <LangFlag id="6" src={RU_flag} alt="Русский" />
        {(visualFacs===false)?<div className={classes.normalSelectedLang} />:<div className={classes.contrastSelectedLang} />}
      </div>
    </div>
  )
}

export default Languages;