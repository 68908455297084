//categories
export const galleriesId = '16';
export const westWingId = '28';
export const southWingId = '29';
export const eastWingId = '21';
export const basilicaId = '15';

//objectTypes
export const epitaphsId = '2';
export const paintingsId = '3';
export const plaquesId = '6';
export const othersId = '8';