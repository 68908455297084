import React from "react";
import ScreenContent from "./ScreenContent";
import BottomFrameWithReturn from "../shared/BottomFrameWithReturn";
import Frame from "../shared/Frame";

const Basilica = () => {
	return(
		<Frame mainClass="basilica" screenContent={<ScreenContent />} bottomFrame={<BottomFrameWithReturn />} />
	);
}
export default Basilica;