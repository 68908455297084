import { useSelector  } from "react-redux";

const Fonts = () => {
  const langId = useSelector(state => state.i18n.locale);
  const cyrillic = ((langId==="5")||(langId==="6"));
  const fonts = {
    initial: (cyrillic) ?'cyrillic-goth':'goth-ball-crap',
    back: (cyrillic)?'movavi-grotesque':'metropolis-bold',
    visuallyImpared: (cyrillic)?'movavi-grotesque':'metropolis-extra-bold',
  }
  return (fonts);
}
export default Fonts;