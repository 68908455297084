export const BooksList = [
  {
    url: "https://polona2.pl/item/46968583",
    title: "Lavacrum conscientiae.",
  },
  {    
    url: "https://polona2.pl/item/47017559",
    title: "Herolt, Ioannes – Sermones Discipuli de tempore et de sanctis cum Promptuario exemplorum et de Beata Virgine.",
  },
  {    
    url: "https://polona2.pl/item/47005857",
    title: "Gritsch, Conradus – Quadragesimale.",
  },
  {    
    url: "https://polona2.pl/item/47032949",
    title: "Celsus, Aulus Cornelius (53 a.C.-7) – De medicina.",
  },
  {    
    url: "https://polona2.pl/item/47033084",
    title: "Iacobus de Voragine – Legenda aurea.",
  },
  {    
    url: "https://polona2.pl/item/47033823",
    title: "Gobius, Ioannes – Scala caeli.",
  },
  {    
    url: "https://polona2.pl/item/47032545",
    title: "Serapion, Ioannes (sen.) – Breviarium medicinae.",
  },
  {    
    url: "https://polona2.pl/item/47033563",
    title: "Anselmus Cantuariensis, (s.) – Opera, cum additionibus Petri Danhauser et Ioannis Löffelholz.",
  },
  {    
    url: "https://polona2.pl/item/47012006",
    title: "Caracciolus, Robertus – Sermones quadragesimales de poenitentia.",
  },
  {    
    url: "https://polona2.pl/item/47036784",
    title: "Gritsch, Conradus – Quadragesimale.",
  },
  {    
    url: "https://polona2.pl/item/47036200",
    title: "Alphonsus de Spina – Fortalitium fidei.",
  },
  {    
    url: "https://polona2.pl/item/47003461",
    title: "Hugo Argentinensis – Compendium theologicae veritatis.",
  },
  {    
    url: "https://polona2.pl/item/47033869",
    title: "Iacobus de Voragine – Legenda aurea.",
  },
  {    
    url: "https://polona2.pl/item/47033938",
    title: "Hugo de Prato Florido – Sermones dominicales super Evangelia et Epistolas.",
  },
  {    
    url: "https://polona2.pl/item/47011906",
    title: "Turrecremata, Ioannes de – Expositio super toto Psalterio",
  },
  {    
    url: "https://polona2.pl/item/47061950",
    title: "Petrus Comestor – Historia scholastica.",
  },
  {    
    url: "https://polona2.pl/item/46967960",
    title: "Hugo Argentinensis – Compendium theologicae veritatis.",
  },
  {    
    url: "https://polona2.pl/item/46968284",
    title: "Nider, Ioannes – Praeceptorium divinae legis, sive Expositio decalogi",
  },
  {    
    url: "https://polona2.pl/item/47017410",
    title: "Vincentius Ferrerius, (s.) – Sermones de tempore et de sanctis. P. I-III.",
  },
  {    
    url: "https://polona2.pl/item/47035850",
    title: "Antoninus Florentinus, (s.) – Confessionale: Defecerunt. Cum Titulo de restitutionibus.",
  },
  {    
    url: "https://polona2.pl/item/46965257",
    title: "Ioannes de Paltz – Septem fores, seu festa Beatae Virginis Mariae.",
  },
  {    
    url: "https://polona2.pl/item/46939752",
    title: "Thesaurus Magistri Sententiarum.",
  },
  {    
    url: "https://polona2.pl/item/46950856",
    title: "Philelphus, Franciscus – Epistolae.",
  },
  {    
    url: "https://polona2.pl/item/47004413",
    title: "Reuchlin, Ioannes – Vocabularius breviloquus etc",
  },
  {    
    url: "https://polona2.pl/item/46953768",
    title: "Gritsch, Conradus – Quadragesimale.",
  },
  {    
    url: "https://polona2.pl/item/47030659",
    title: "Petrus Lombardus (ca 1095-1159) – Sententiarum libri IV.",
  },
  {    
    url: "https://polona2.pl/item/47028166",
    title: "Pseudo-Petrus de Palude – Sermones Thesauri novi de tempore et de sanctis. P. I-II.",
  },
  {    
    url: "https://polona2.pl/item/47033403",
    title: "Petrus Lombardus (ca 1095-1159) – Sententiarum libri IV, cum commento Bonaventurae.",
  },
  {    
    url: "https://polona2.pl/item/47033497",
    title: "Antonius, Andreae – Quaestiones super XII libros Metaphysices Aristotelis. Ed. Lucas de Subereto.",
  },
  {    
    url: "https://polona2.pl/item/47030590",
    title: "Pseudo-Petrus de Palude – Sermones Thesauri novi de sanctis.",
  },
  {    
    url: "https://polona2.pl/item/47011712",
    title: "Biblia, Lat.",
  },
  {    
    url: "https://polona2.pl/item/47018023",
    title: "Guillelmus Parisiensis – Postilla super Epistolas et Evangelia.",
  },
  {    
    url: "https://polona2.pl/item/46955013",
    title: "Awicenna – Canon",
  },
  {    
    url: "https://polona2.pl/item/47017071",
    title: "Guillelmus Peraldus – Summa de vitiis.",
  },
  {    
    url: "https://polona2.pl/item/47033426",
    title: "Vincentius Ferrerius, (s.) – Sermones de tempore et de sanctis. P. I-III.",
  },
  {    
    url: "https://polona2.pl/item/47084947",
    title: "Britannicus, Gregorius – Sermones funebres. ",
  },
  {    
    url: "https://polona2.pl/item/47061459",
    title: "Nider, Ioannes – Praeceptorium divinae legis, sive Expositio decalogi.",
  },
  {    
    url: "https://polona2.pl/item/46883604",
    title: "Pseudo-Bonaventura – De castitate et munditia sacerdotum.",
  },
  {    
    url: "https://polona2.pl/item/46880886",
    title: "Chaimis, Bartholomaeus de – Confessionale.",
  },
  {    
    url: "https://polona2.pl/item/47017618",
    title: "Marchesinus, Ioannes – Mammotrectus super Bibliam.",
  },
  {    
    url: "https://polona2.pl/item/46885773",
    title: "Bonaventura, (s.) – De praeparatione ad missam.",
  },
  {    
    url: "https://polona2.pl/item/47012140",
    title: "Bartholomaeus, Anglicus – De proprietatibus rerum.",
  },
  {    
    url: "https://polona2.pl/item/47028247",
    title: "Bernardinus Senensis – Quadragesimale de Christiana religione.",
  },
  {    
    url: "https://polona2.pl/item/46966262",
    title: "Bernardus Legnicensis – Dialogus Virginis Mariae misericordiam elucidans",
  },
  {    
    url: "https://polona2.pl/item/47006820",
    title: "Pelbartus de Themeswar – Stellarium coronae Beatae Mariae Virginis.",
  },
  {    
    url: "https://polona2.pl/item/46941047",
    title: "Flores legum.",
  },
  {    
    url: "https://polona2.pl/item/47004485",
    title: "Nicolaus de Błonie – Sermones de tempore et de sanctis, sive Viridarius.",
  },
  {    
    url: "https://polona2.pl/item/47005538",
    title: "Anselmus Cantuariensis, (s.) – Opera, cum additionibus Petri Danhauser et Ioannis Löffelholz.",
  },
  {    
    url: "https://polona2.pl/item/46966611",
    title: "Henricus de Langenstein – Expositio super Orationem Dominicam et Ave Maria.",
  },
  {    
    url: "https://polona2.pl/item/46969580",
    title: "Philippus de Monte Calerio – Dominicale seu Compendium postillae, abbreviatum per Ionselmum de Canova.",
  },
  {    
    url: "https://polona2.pl/item/46886933",
    title: "Chaimis, Bartholomaeus de – Confessionale.",
  },
  {    
    url: "https://polona2.pl/item/47027773",
    title: "Cassianus, Ioannes – De institutis coenobiorum; Collationes patrum XXIV.",
  },
  {    
    url: "https://polona2.pl/item/47011855",
    title: "Pseudo-Petrus de Palude – Sermones Thesauri novi de sanctis.",
  },
  {    
    url: "https://polona2.pl/item/46965985",
    title: "Bollanus, Dominicus – De conceptione Beatae Virginis Mariae.",
  },
  {    
    url: "https://polona2.pl/item/46942438",
    title: "[Hugo Argentinensis] – Epitome al[ia]s Compe[n]diu[m] theologice veritatis…",
  },
  {    
    url: "https://polona2.pl/item/47006219",
    title: "Pseudo-Petrus de Palude – Sermones Thesauri novi de tempore",
  },
  {    
    url: "https://polona2.pl/item/46886164",
    title: "Dionysius Carthusianus – Specula omnis status vitae humanae.",
  },
  {    
    url: "https://polona2.pl/item/46949552",
    title: "Niger, Franciscus – Modus epistolandi.",
  },
  {    
    url: "https://polona2.pl/item/46942833",
    title: "Servasanctus de Faenza – Sermones de tempore et de sanctis.",
  },
  {    
    url: "https://polona2.pl/item/46944473",
    title: "Biel, Gabriel – Sermones. T. I-IV.",
  },
  {    
    url: "https://polona2.pl/item/46961655",
    title: "Baptista Mantuanus – De suorum temporum calamitatibus, cum additionibus Theodorici Ulsenii, Petri Bonomi et Francisci Cereti; Contra poetas impudice loquentes.",
  },
  {    
    url: "https://polona2.pl/item/47033266",
    title: "Petrus Lombardus (ca 1095-1159) – Sententiarum libri IV, cum commento Bonaventurae. P.I-IV.",
  },
  {    
    url: "https://polona2.pl/item/47033719",
    title: "Bernardus de Gordonio – Practica, seu Lilium medicinae etc.",
  },
  {    
    url: "https://polona2.pl/item/47033685",
    title: "Homiliarius doctorum de tempore et de sanctis. P. I-II",
  },
  {    
    url: "https://polona2.pl/item/47036428",
    title: "Pelbartus de Themeswar – Sermones Pomerii de tempore. P.I-II.",
  },
  {    
    url: "https://polona2.pl/item/46855846",
    title: "Guillelmus Alvernus – Rhetorica divina etc.",
  },
  {    
    url: "https://polona2.pl/item/46859016",
    title: "Guillelmus de Lanicia – Diaeta salutis. ",
  },
  {    
    url: "https://polona2.pl/item/46681499",
    title: "Britnnicus, Gregorius. – Sermones funebres et nuptiales. Cum additione Pauli Zane, episcopi Brixiensis.",
  },
  {    
    url: "https://polona2.pl/item/46681277",
    title: "Ioannes de Aquila – Sermones quadragesimales, cum additionibus Danielis Vincentini. Ed. Benedictus Brixianus.",
  },
  {    
    url: "https://polona2.pl/item/46863452",
    title: "Dorlandus, Petrus – Viola animae",
  },
  {    
    url: "https://polona2.pl/item/46878267",
    title: "Guido de Monte Rochen – Manipulus curatorum.",
  },
  {    
    url: "https://polona2.pl/item/46862504",
    title: "Suso, Henricus – Horologium eternae sapientiae.",
  },
  {    
    url: "https://polona2.pl/item/46868902",
    title: "Alexander VI (papa) – Bulla contra errantes infideles, haereticos et adversarios in rebus politicis. (Consueverunt Romani Pontifices…), Romae 28 III 1499.",
  },
  {    
    url: "https://polona2.pl/item/46871105",
    title: "Casus papales, episcopales et abbatiales",
  },
  {    
    url: "https://polona2.pl/item/46877449",
    title: "Nicolaus de Błonie – De sacramentis.",
  },
  {    
    url: "https://polona2.pl/item/46865827",
    title: "Ferrariis, Albertus de – De horis canonicis.",
  },
  {    
    url: "https://polona2.pl/item/46872763",
    title: "Antonius de Bitonto – Sermones dominicales per totum annum. Ed. Philippus de Rotingo.",
  },
  {    
    url: "https://polona2.pl/item/46859851",
    title: "Gerardus de Zutphania – De spiritualibus ascensionibus etc.",
  },
  {    
    url: "https://polona2.pl/item/46884466",
    title: "Bonaventura, (s.) – Breviloquium.",
  },
  {    
    url: "https://polona2.pl/item/46878647",
    title: "Michael de Hungaria – Sermones praedicabiles per totum annum",
  },
  {    
    url: "https://polona2.pl/item/47035573",
    title: "Antoninus Florentinus, (s.) – Confessionale: Defecerunt. Cum Titulo de restitutionibus et conclusionibus et decisionibus in foro conscientiae. ",
  },
  {    
    url: "https://polona2.pl/item/47034373",
    title: "Iosephus Flavius (ca 37-103) – De antiquitate Iudaica.",
  },
  {    
    url: "https://polona2.pl/item/47035698",
    title: "Ioannes de Aquila – Sermones quadragesimales, cum additionibus Danielis Vicentini. Ed. Benedictus Brixianus.",
  },
  {    
    url: "https://polona2.pl/item/47035747",
    title: "Ioannes de Lapide – Resolutorium dubiorum circa celebrationem missarum occurrentium.",
  },
  {    
    url: "https://polona2.pl/item/47035895",
    title: "Chaimis, Bartholomaeus de – Confessionale.",
  },
  {    
    url: "https://polona2.pl/item/47004024",
    title: "Bernardinus Senensis – Sermones de festivitatibus Virginis gloriosae.",
  },
  {    
    url: "https://polona2.pl/item/47028196",
    title: "Turrecremata, Ioannes de – Quaestiones evangeliorum de tempore et de sanctis et Flos Theologiae.",
  },
  {    
    url: "https://polona2.pl/item/47033635",
    title: "Lambertus de Monte – Copulata super octo libros Physicorum Aristotelis iuxta doctrinam Thomae de Aquino, cum textu.",
  },
  {    
    url: "https://polona2.pl/item/47034278",
    title: "Tudeschis, Nicolaus de – Lectura super quinque libros Decretalium. P. I-VI.",
  },
  {    
    url: "https://polona2.pl/item/47064041",
    title: "Meffret – Sermones de tempore et de sanctis, sive Hortulus reginae. P. I-III.",
  },
  {    
    url: "https://polona2.pl/item/47036650",
    title: "Holkot, Robertus – Super sapientiam Salomonis.",
  },
  {    
    url: "https://polona2.pl/item/47064486",
    title: "Petrus Lombardus – Sententiarum libri IV, cum commento Bonaventurae. P.I-IV.",
  },
  {    
    url: "https://polona2.pl/item/47084730",
    title: "Marulić, Marko – Quinquaginta parabolae.",
  },
  {    
    url: "https://polona2.pl/item/47178717",
    title: "Baron, Stephanus – Sermones declamati coram alma universitate Cantabrigiensi. – Tractatulus de regimine principum. ",
  },
  {    
    url: "https://polona2.pl/item/47178718",
    title: "Novum insigneq[ue] opusculu[m] pro Christi verbu[m] eua[n]geliza[n]tibus … ",
  },
  {    
    url: "https://polona2.pl/item/47183729",
    title: "Duns Scotus, Ioannes – Opusculum preclarissimum Ioannis Scoti conclusiones numero CCCLXXVIII complectens, quas ex libris Metaphisic[a]e Aristotelis edidit… ",
  },
  {    
    url: "https://polona2.pl/item/47065972",
    title: "Meffret – Sermones de tempore et de sanctis, sive Hortulus reginae. P. I-III.",
  },
  {    
    url: "https://polona2.pl/item/47066261",
    title: "Ambrosius, (s.) – Opera. Cum additionibus Ioannis de Lapide. P. I-III",
  },
  {    
    url: "https://polona2.pl/item/46960391",
    title: "Baldung, Hieronymus – Aphorismi compunctionis theologicales.",
  },
  {    
    url: "https://polona2.pl/item/47035952",
    title: "Antoninus Florentinus, (s.) – Confessionale: Defecerunt.",
  },
  {    
    url: "https://polona2.pl/item/47034233",
    title: "Vincentius Bellovacensis – Speculum historiale.",
  },
  {    
    url: "https://polona2.pl/item/47034037",
    title: "Herolt, Ioannes – Sermones Discipuli de tempore et de sanctis cum Promptuario exemplorum et de Beata Virgine.",
  },
  {    
    url: "https://polona2.pl/item/47034503",
    title: "Gritsch, Conradus – Quadragesimale.",
  },
  {    
    url: "https://polona2.pl/item/47034864",
    title: "Graduale Romanum. Ed. Franciscus de Brugis. P. I-III.",
  },
  {    
    url: "https://polona2.pl/item/47066538",
    title: "Rhasis, Muhammad – Liber nonus ad Almansorem etc.",
  },
  {    
    url: "https://polona2.pl/item/46961326",
    title: "Lavacrum conscientiae.",
  },
  {    
    url: "https://polona2.pl/item/47014631",
    title: "Magistri Johannis Gerson – De vita Spirituali",
  },
  {    
    url: "https://polona2.pl/item/47014625",
    title: "przez X. Piotra Błachowicza […] – Kazanie na uroczyste wprowadzenie Statuy Nayświętszey Maryi Panny Loretańskiey […]",
  },
  {    
    url: "https://polona2.pl/item/47014621",
    title: "przez X. Tomasza Stefanowskiego Proboszcza Janiszewskiego miane – Kazanie na żałobnym nabożeństwie S. P. W. Jmc. Pani Jadwigi Osińskiey, Stolnikowy Chełmińskiey […]",
  },
  {    
    url: "https://polona2.pl/item/47014619",
    title: "przez X. Adriana Smardowskiego […] – Mowa żałobna przy oddawniu ciała ś. p. W. J. P. Maxymy z Szembekow Pstrokoński […]",
  },
  {    
    url: "https://polona2.pl/item/47014694",
    title: "Reflexyi Albo Myśli Chrześciańskich Na cały Rok rozłożonych. Cz. 3, Zamykająca w sobie Lipiec, Sierpien, Wrzesien.",
  },
  {    
    url: "https://polona2.pl/item/47014618",
    title: "Odezwa jednego z Kaznodziejow do odchodzących z missyi, odprawioney w Krakowie roku 1818 w dniach października.",
  },
  {    
    url: "https://polona2.pl/item/47014634",
    title: "[Lotharius … dyaconus] – Liber de vilitate conditionis humane",
  },
  {    
    url: "https://polona2.pl/item/47014688",
    title: "par M. Compans […] – Histoire de la vie de Jésus-Christ […]. Tome second",
  },
  {    
    url: "https://polona2.pl/item/47014624",
    title: "przez X. Jana Piotrowskiego […] – Kazanie na dzień pamiątki siedmiu boleści Panny Maryi",
  },
  {    
    url: "https://polona2.pl/item/47014654",
    title: "Lohner, Tobias – Instructio Practica Secunda De Horis Canonicis, Iuxta Rubricas Breviarii Romani Rite Recitandis : In Qua Etiam Ipsae Rubricae Notis Brevibus Illustratae, Et Martyrologii Romani Nomina Suis Accentibus Signata Continentur […]",
  },
  {    
    url: "https://polona2.pl/item/47014617",
    title: "Przez X. Grzegorza Zacharyasiewicza Kanonika Metr: Gnieznieńsk […] – Kazanie Załobne Po wielkiey i drogiey pamięci Mężu JO. Xciu Antonim Kazimierzu z Ostrowa Ostrowskim Arcybiskupie Gnieźnieńskim S. Stolicy Apostolskiey Pośle Urodzonym, Królestwa Polsk. i W. X. Litewsk. Prymasie i Pierwszym Xciu […] przy złożeniu serca jego w kościele parafialnym Skierniewskim miane",
  },
  {    
    url: "https://polona2.pl/item/47014643",
    title: "Miane przez X. Adama Cedrowicza kościoła parafialnego Skierniewskiego kapłana […] – Kazanie na pogrzebie ś. p. Jaśnie Wielmożnego Jmci Pana Franciszka z Brzezia hrabi Lanckoronskiego Starosty Rawskiego Regnowskiego &amp;c. &amp;c. w kościele Regnowskim dnia 11. Kwietnia 1785",
  },
  {    
    url: "https://polona2.pl/item/47014695",
    title: "Przez Xiędza Berarda Zołądkiewicza, Zakonu S. O. Franciszka Wielkopolskiey Prowincyi Reformata, Kaznodzieię. Ordynaryusza w Kollegiacie Kaliskiey, a teraz Gwardyana Konińskiego […] – Kazania Przygodne Na Niektore Swięta, Po rożnych mieyscach na Odpustach Miane",
  },
  {    
    url: "https://polona2.pl/item/47014691",
    title: "przez X. Alex. Brodowskiego S. J. Theologa – Listy pewnego Doktora Akademii Strazburskiey Katolickiej do iednego szlachcica protestanta : O sześciu przeszkodach do zbawienia znajdujących się w religii luterskiej, z francuzkiego na polski język przetłumaczone",
  },
  {    
    url: "https://polona2.pl/item/47014651",
    title: "Jacobi Pontani […] Attica Bellaria : Sive Literatorum secundae Mensae ad animos ex contentione […] ac Syntagmatum Omnium Et Antehac tribus partibus editorum Libri Tres &amp; lassitudine studiosorum lectiunculis exquisitis, iucundis ac honestis relaxandos ac syntagmatum omnium et ante hac tribus partibus editorum libri tres : nunc alio charactere compendiosius unico volumine comprehensi.",
  },
  {    
    url: "https://polona2.pl/item/47014686",
    title: "Authore Laurentio Duhan, licentiatio theologo, socio Sorbonico, &amp; philosophiae professore in Sorbonae plessaeo Academiae Parisiensis – Philosophus, in utramque partem : sive selectae limatae difficultates in utramque partem : cum responsionibus […]",
  },
  {    
    url: "https://polona2.pl/item/47014607",
    title: "Rader, Matthäus (1561-1634) – Syntagma De Statv Morientivm : Ex Mss. codicibus illustrium bibliothecarum Sereniss. Maximiliani Boiorum Ducis, &amp; Reipub. Avgvstanae depromtum, Latine factum notisq[ue] illustratum",
  },
  {    
    url: "https://polona2.pl/item/47014652",
    title: "przez X. Sebastiana Styiewicza […] – Kazania Na Swięta Vroczyste Pana Zbawiciela Naszego, Naswiętszey Panny Maryey, I Swietych Panskich : Na Cześć y na Chwałę P. Bogv Wszechmogącemu : Na Zbudowanie Duchowne y pożytek pobożnym Katholikom",
  },
  {    
    url: "https://polona2.pl/item/47014690",
    title: "auctore P. Gab. Franc. Le Jay, e Soc. J. – Bibliotheca rhetorum : praecepta et exempla complectens : quae tam ad oratoriam facultatem quam ad poeticam pertinent, discipulis pariter ac magistris perutilis",
  },
  {    
    url: "https://polona2.pl/item/47014627",
    title: "Contraxit Petrus Collet […] – Institutiones Theologiae Scholasticae Quas Ad Usum Seminariorum E Propiis Suis Praelectionibus Contraxit Petrus Collet […]. T. 2, Continens Tractatus: I. De Gratia Christi Salvatoris. II. Qui est Autoris, De Vera Religione. III. De Ecclesia. […]",
  },
  {    
    url: "https://polona2.pl/item/47014628",
    title: "a Martino Baronio […] pars prima […] – Vitae, Gesta et Miracvla, Sanctorum Quinq. Fratrum Polonorum Eremitarum Casimiriensium, Ordinis S. Romualdi Abbatis Camaldul. Ioannis, Benedicti, Mathei, Isaaci, Christini, Martyrum simulque et aliorum sociorum eorum Athanasii, Lavrentii [et] Bogvmili : ex variis Authoribus diuersisque fragmentis breuiter collecte [et] nunc primum in lucem aeditae, avthoritate […] Bernardi Macieiowski […]1606",
  },
  {    
    url: "https://polona2.pl/item/47014649",
    title: "Przekładania Jacka Przybylskiego – Miltona Ray utracony, ksiąg dwanaście z angielskiego",
  },
  {    
    url: "https://polona2.pl/item/47014689",
    title: "Römischer Katechismus : auf Befehl Sr. Päbstlichen Heilikeit Pius des V. für die Seelsorger herausgegeben : Nunmehro auf Veranstaltung des Hochwürdigen und Hochgebohrner Herrn, Herrn, CHRISTOPH der Heil. röm. Kirche Cardinal Priesters, des heil. röm. Reichs Fürsten, Erzbischofs zu Wienn, aus den Grafen von Migazzi [et]c. [et]c. zum allgemeinen Gebrauche aus dem Lateinischen uns Deutsche übersetzt. Zweyter Thiel.",
  },
  {    
    url: "https://polona2.pl/item/47014687",
    title: "przez […] Franciszka De Salignac De La Motte Fenelona […] – Przypadki Telemaka Syna Ulisesa",
  },
  {    
    url: "https://polona2.pl/item/47014653",
    title: "Compendium Legum ex Statuto et Constitutionibus regni Poloniae quae videlicet tantum ad continuum Usum Judicantium, et Litigantium requiruntur, ex farragine Legum per Alphabetum ad Annum 1736 excerptum.",
  },
  {    
    url: "https://polona2.pl/item/47014692",
    title: "Per Fratrem Ludovicum Lipsin Ordinis Fratrum Minorum Conventualium – Compendiosa Historia Vitae Seraphici Patris Francisci : In Formam Dialogi […]",
  },
  {    
    url: "https://polona2.pl/item/47014648",
    title: "Przekładania Jacka Przybylskiego – Miltona Ray Odzyskany, Księgi Cztery z Aangielskiego",
  },
  {    
    url: "https://polona2.pl/item/47034119",
    title: "Leonardus de Utino – Sermones de sanctis.",
  },
  {    
    url: "https://polona2.pl/item/47014626",
    title: "Lettere Moderne Di D. Isidoro Nardi Accademico Arcade […] – Il segretario principiante ed istrutto",
  },
  {    
    url: "https://polona2.pl/item/47014650",
    title: "Mowy wyborne po większey częsci z starozytnych mowcow i dziejopisow zebrane : ku sławie y pozytkowi narodu wydane […]. T. 1.",
  },
  {    
    url: "https://polona2.pl/item/47014611",
    title: "Przez X. Marcina Rubczyńskiego S.T.D. Ex-Prowincyała Prowincyi Ruskiey, Zakonu Karmelitańskiego D. O. Roku Wcielonego Słowa Boskiego 1768 – Głos Pana Kruszącego Cedry Libańskie Albo Rekollecye Osobom Zakonnym Służące : w Ktorych Obowiązki życia Zakonnego do uwagi, y powinnego zachowania podaią się. Na dni dziesięć podzielone y powtórnie wydane [Cz.1]-2",
  },
  {    
    url: "https://polona2.pl/item/47014684",
    title: "Gregorii Mayer Theolog. Doctor. Et Lingvarvm Orientalivm Et Hermeneutices S. Profess. Pvbl. Ord. Institvtio Interpretis Sacri.",
  },
  {    
    url: "https://polona2.pl/item/47014682",
    title: "Mowy X. Bonawentury Bujalskiego […] podczas Urzędu Prowincyalstwa według prawa przy klasztornych wizytach oraz innych okolicznościach do zakonników miane : Z przyłączeniem dwóch kazań tegoż autora o św. Jozefie z Kopertynu Zakonu OO. Franciszkanow : Dzieło przełożonym duchownym rządcom, kaznodziejom i wszystkim zakonnym osobom użyteczne.",
  },
  {    
    url: "https://polona2.pl/item/47014704",
    title: "Ex Idiomate Gallico Latinitas Reddidit Et Cum Animadversionibus Justi Henn. Boehmeri, […]; edidit Joan Daniel Gruber […] – Claudii Fleury […] Institutiones Juris Ecclesiastici",
  },
  {    
    url: "https://polona2.pl/item/47014685",
    title: "/ Studio Et Opera Johan: Huldrici Herlini, Collegii Bernensis Ephori – Analysis Isagogica : Seu Isagoge Analytica Ad Lectionem Librorum Biblicorum Omnium, Tribus Distinctis Partibus edita : Ad Rerum Et Argumentorum Summam Seriemque facilius intelligentia ac memoria complectendam : Cum Versibus mnēmonikois Triplicibus. Pars Prima Complectens Isagogen Ad Lectionem Librorum Mosaicorum &amp; Historicorum",
  },
  {    
    url: "https://polona2.pl/item/47014703",
    title: "Edme -Marie-Joseph Lemoine – Traité Élémentaire de Mathématiques pures : au Principes d’Arithmétique […]. T. 1, Principes d’Arithmétique et de Géométrie […]",
  },
  {    
    url: "https://polona2.pl/item/47014683",
    title: "Sette strali d’amore vibrati da Giesù Christo in croce all’anima fedele spiegati da D. Agostino Lampugnani, priore casinense.",
  },
  {    
    url: "https://polona2.pl/item/47014609",
    title: "Authore R. P. Stanislao Zakrzewski, Canon. Reg. Monas. Czeruen. B. V. M. […] – Orationvm Ecclesiasticarvm Dominicalium Tomi Primi Pars Prima. n T. 1, P. 1",
  },
  {    
    url: "https://polona2.pl/item/47014680",
    title: "Eryci Pvteani Diva virgo Aspricollis : beneficia ejus &amp; miracvla novissima.",
  },
  {    
    url: "https://polona2.pl/item/47014610",
    title: "Authore R. P. Stanislao Zakrzewski Can. Reg. S. T. L. Ordinario […] – Josephus Patriarcha : Sev Oratio Ecclesiastica De B. Ignatio Fundatore Societatis Iesv",
  },
  {    
    url: "https://polona2.pl/item/47014679",
    title: "Continuatio praelectionum theologicarum Honorati Tournely : sive Tractatus de universa theologia morali. T. 1, Continens Tractatus I. De Jutsitia et Jure; II De contractibus […].",
  },
  {    
    url: "https://polona2.pl/item/47014681",
    title: "a Friderico Fornero, Episcopo Hebronensi, Suffraganeo Bambergensi […] – Paradisus Evangelicus Malorum Punicorum cum Pomorum Fructibus. Dominicae Passionis, Mortis, Resurrectionis, &amp;c. Mysteria Et ArcanaOmnia, Et Singula, Concionibus explicata […]. T. 3, Paradisi Evangelici Apophoreta : Id est Appositi Salutaris Poenitentiae Fructus : sive Regis Hebronensis […]",
  },
  {    
    url: "https://polona2.pl/item/47014702",
    title: "Concinnata A P. Edmundo Voit Societatis Jesu […] – Theologia Moralis : Ex Solidis Probatorum Auctorum Principiis Et Variorum Casuum fictorum, &amp; factorum Resolutionibus […] Ad Commodiorem Usum In Alma Universitate Herbipolensi. T. 2, De Sacramentis in Genere, &amp; in Specie; item de Censuris Ecclesiasticis, &amp; Irregularitatibus",
  },
  {    
    url: "https://polona2.pl/item/47014705",
    title: "Auctore F. Natali Alexandro […] – Institutio concionatorum : seu, Praecepta et Regulae, ad Praedicatores Verbi Divini informandos : Ex SS. Patribus, et instructionibus S. Caroli Borromaei, depromptae",
  },
  {    
    url: "https://polona2.pl/item/47014672",
    title: "del P. Fr. Filippo Maria di S. Paolo carmelitano scalzo […] – Discorsi sagri. T. 1, Nelle feste d’alcuni santi […]",
  },
  {    
    url: "https://polona2.pl/item/47014623",
    title: "przez X. Benedykta Maiewskiego […] miane […] – Kazanie na pogrzebie Wielmożnego Szymona Komorowskiego skarbnika poznańskiego",
  },
  {    
    url: "https://polona2.pl/item/47014700",
    title: "przez X. Wawrzyńca Gębickiego, z łaski Boskey Biskupa Chełmińskiego […] potwierdzony – Porządek nowicyatu z reguły s. oyca naszego Benedykta zabrany y Sporządzony do ćwiczenia duchownego y powierzchnego : na ośm części głowne rozdzielony […]",
  },
  {    
    url: "https://polona2.pl/item/47014662",
    title: "a R.P. Salvatore Bartolucio assisiate […] scoticae illustratore, fidelissime recognitae, […] locupletate, &amp; exornatae – F. Ioan, Duns Scoti, ordinis minorum […] Quaestiones Quatuor Voluminum scripti Oxoniensis Super Sententias. T. 4",
  },
  {    
    url: "https://polona2.pl/item/47014620",
    title: "przez X. Benedykta Maiewskiego Bernardyna […] miane […] – Kazanie na pogrzebie Wielmożnego Szymona Komorowskiego skarbnika poznańskiego",
  },
  {    
    url: "https://polona2.pl/item/47014622",
    title: "przez X. Bernarda Bobrowicza […] miane […] – Kazanie na obchodzie żałobnym za duszę ś. p. Tadeusza Kościuszki w kościele katedralnym Poznańskim",
  },
  {    
    url: "https://polona2.pl/item/47014699",
    title: "Z Włoskiego na Oyczysty ięzyk Przez iednego Kapłana Swieckiego Dyecezyi Krakowskiey Przetłomaczone. Y dla pożytku Duchownego Do druku Podane – Maxymy Zycia Chrześciańskiego w XXXI. Uwagach Do Zbawienia należących : Na każdy dzień Miesiąca do rozmyślania,",
  },
  {    
    url: "https://polona2.pl/item/47014616",
    title: "przez Daniela Sarneckiego – Kazanie pod czas kapituły prowincyalskiéy w Kościele XX. Franciszkanów Łagiewnickich dnia 9 października 1814 roku",
  },
  {    
    url: "https://polona2.pl/item/47014635",
    title: "Traktaty Między Mocarstwami Europeyskiemi Od Roku 1648. Zaszłe Do Roku 1763 : Podług Lat Porządku Z przyłączoną potrzebney Historyi wiadomością Opisane. T. 3.",
  },
  {    
    url: "https://polona2.pl/item/47014637",
    title: "Traktaty Między Mocarstwami Europeyskiemi Od Roku 1648. Zaszłe Do Roku 1763 : Podług Lat Porządku Z przyłączoną potrzebney Historyi wiadomością Opisane. T. 2.",
  },
  {    
    url: "https://polona2.pl/item/47014636",
    title: "Traktaty Między Mocarstwami Europeyskiemi Od Roku 1648. Zaszłe Do Roku 1763 : Podług Lat Porządku Z przyłączoną potrzebney Historyi wiadomością Opisane. T. 5.",
  },
  {    
    url: "https://polona2.pl/item/47014701",
    title: "Po Większey Częsci Z Francuskich Wybrane a Przez X. Jana Kantego Gładysza K. Ł. Na Oyczysty Język Przetłomaczone. Tomik […] – Kazania Dla Przygody. T. 1 i [2]",
  },
  {    
    url: "https://polona2.pl/item/47014613",
    title: "przez Antoniego Kotowskiego kanonika i surrogata warszawskiego, proboszcza piaseczyńskiego dnia 18 września 1821 r. w Wilanowie miane – Kazanie w czasie religiynych obrzędow przy pogrzebie zwłok ś. p. JW. Stanisława hrabiego Potockiego Woiewody Prezesa Senatu Królestwa Polskiego, wielu orderów kawalera",
  },
  {    
    url: "https://polona2.pl/item/47014646",
    title: "Rituale sacramentorum, ac aliarum Ecclesiæ cæremoniarum […] Ad uniformem Ecclesiarum Regni Poloniae, &amp; Magni Ducatus Lithvaniae usum auctum […].",
  },
  {    
    url: "https://polona2.pl/item/47014693",
    title: "La Journee du chretien, sanctifiee par la priere et la meditation.",
  },
  {    
    url: "https://polona2.pl/item/47014615",
    title: "powiedziane przez X. Ludwika Długołeksiego, franciszkanka kaliskiego – Kazanie w czasie uroczystego grzebania zwłokow […] Kajetana Miłokowskiego, bywszego kapitana wojsk polskich…",
  },
  {    
    url: "https://polona2.pl/item/47014614",
    title: "przez X. Jana Kałuszewskiego Franciszkanka […] – Kazanie o Zwierzchności J. W. Jmci Xiędzu Franciszkowi Kandydowi Hrabi na Tenczynie Ossolińskiemu Biskupowi Kiiowskiemu y Czerniechowskiemu Prezydentowi Kapituły Prowincyalney w Niedzielę ośmnastą po świątkach na Kapitule Prowincyalney Xięży Franciszkanów miane w Konwencie Wareckim",
  },
  {    
    url: "https://polona2.pl/item/47014647",
    title: "Przez X. Gracyana Piotrowskiego, Scholarum Piarum – Kazania Przeciwko Zdaniom y Zgorszeniom Wieku Naszego Na Swie̜ta Całego Roku : Iako Też Y Inne Moralne Miane. T. 2",
  },
  {    
    url: "https://polona2.pl/item/47014698",
    title: "Odpusty od Oyca Swiętego Benedykta papieża XIV : tak nauczającym, y uczącym się sposobow modlitwy wenętrzney, iako też y same modlitwe […].",
  },
  {    
    url: "https://polona2.pl/item/47014630",
    title: "per venerabilem fratrem Bartholomeum de chaimis de mediolano ordinis mino[rum] […] – Incipit interrogatorium siue confessionale",
  },
  {    
    url: "https://polona2.pl/item/47014641",
    title: "przez W. J. X. Jędrzeja Filipeckiego kanonika Lwowskiego miane – Podczas poświęcenia na biskupstwo Targońskie JW. Najprzewielebnieyszego J. X. Ferdynanda na Kitkach Kickiego Sufragana Lwowskiego, proboszcza katedry lwowskiey i krakowskiey, kazanie",
  },
  {    
    url: "https://polona2.pl/item/47014697",
    title: "quod in homine Christiano, […] Pater Stanislaus à Jesu Maria Papczyński […], demonstravit, Opusculum cuivis hominum statui maximè proficuum &amp; ad Sermones Dedicationis Ecclesiae sum[m]opere accommodum : Avitae Portae […] D. Antonii Ostrowski Cathedralis Canonici Posn. […], in recognitionem obsequi a congregatione Mariana dedicatum – Templum Dei mysticum",
  },
  {    
    url: "https://polona2.pl/item/47014642",
    title: "Przez JX. Tadeusza Paszkowskiego […] – Kazanie Na Uroczystość S. Stanisława Biskupa Męczennika i Orderu Jego : Dnia 8. Maia Roku 1783. W Kościele JJ. XX. Missionarzow Warszawskim Miane",
  },
  {    
    url: "https://polona2.pl/item/47014632",
    title: "Cirillus Episcopus – Speculum sapientiae : Alias quadripartitus apologieticus vocatus",
  },
  {    
    url: "https://polona2.pl/item/47014645",
    title: "przez X. Józefa Męcińskiego miane i do druku z dozwoleniem zwierzchności podane […] – Kazanie pod czas pogrzebu Jaśnie Wielmożnego s. p. Macieja Sołtyka woiewody sandomierskiego […] w kościele stopnickim xx. Reformatów dnia 31. Maia 1802. roku",
  },
  {    
    url: "https://polona2.pl/item/47014644",
    title: "przez X. Błażeia Chmielewskiego Zakonu S. O. Franciszka Prowincyi N. M. Panny Anielskiey Reformata […] – Kazanie na pogrzebie Wielmożney Agnieszki z Kuczkowskich Jasiński Kapitanówny",
  },
  {    
    url: "https://polona2.pl/item/47014678",
    title: "Thesavri Polonolatinograeci Gregorii Cnapii […] Tomvs […]. T. 2, Latinopolonicvs, Simul idem Index verborum primi Tomi, ab Auctore confectus, &amp; secundae editioni correctae ac multum auctae, accommodatus […].",
  },
  {    
    url: "https://polona2.pl/item/47014606",
    title: "a Matthaeo Radero De Societate Iesv, ad Illustrissimos […] principes Radzivilos […] – Viridarivm Sanctorvm examenaeis graecorum lectum, translatum et annotationibus similibusque passim, historiis […] illustratum",
  },
  {    
    url: "https://polona2.pl/item/47014676",
    title: "Napisany przez W. O. M. Roberta Nutego ; a Powtornie z Włoskiego na Polskie, przez W. X. Franciszka Iabłonskiego […] Przetłvmaczony […] Roku […] 1688 – Zywot Słvgi Bozego W. O. Iozefa z Kopertynu Kapłana Zakonu S. Franciszka Minorum Conuentualium albo Franciszkanow : Ktory zsedł z tego świata Dnia 18. Miesiąca Września Roku […] 1663. y pogrzebiony w Kościele S. Franciszka v Oycow tegoż Zakonu w Ozymie [!]",
  },
  {    
    url: "https://polona2.pl/item/47014677",
    title: "Auctore F. Mauricio Hylareto – In quinquaginta duarum dominicarum totius anni Euangelia : quae iuxta Sanctae Rom. Ecclesiae, vsum, leguntur, Homiliae. – nunc primùm in lucem editae : ac in thessaradecadas quatuor facili methodo distributae, &amp; in duos tomos partitae. Tomus primus",
  },
  {    
    url: "https://polona2.pl/item/47014673",
    title: "del P. Fr. Filippo Maria di S. Paolo carmelitano scalzo […] – Discorsi sagri. T. 2, Dell’ Avgvstissimo sagramento […]",
  },
  {    
    url: "https://polona2.pl/item/47014655",
    title: "Splendiano Andrea Pennazzi da Soriano […] – Epitome De’ Sagri Riti : E Delle Cerimonie Della Messa Privata Secondo la Rubrica del Messale Romano",
  },
  {    
    url: "https://polona2.pl/item/47014608",
    title: "Avctore R. P. F. Didaco De La Vega, S.S. Theologiae Lectore, ordinis S. Francisci. Cum Indicibus locupletissimis &amp; vtilissimis – Conciones Qvadragesimales A Septvagesima Vsqve Ad Feriam Secnvdam resurrectionis",
  },
  {    
    url: "https://polona2.pl/item/46855465",
    title: "Guillelmus Alvernus – Rhetorica divina etc.",
  },
  {    
    url: "https://polona2.pl/item/47034761",
    title: "Schedel, Hartmann – Liber chronicarum.",
  },
  {    
    url: "https://polona2.pl/item/47014671",
    title: "del R.P. humile Segala dell’Ordine di S. Francesco – Nuovo ambasciatore, il quale annuntia al mondo le grandezze, le eccellenti virtù, e gli alti privilegi concessi dall’altissimo Dio, alla sua gran madre Maria vergine, imperatrice del cielo, e della terra : e scuopre mirabili essercitii, arrichiti, &amp; adornati di bellissi miessempi per farsi di lei veri divoti",
  },
  {    
    url: "https://polona2.pl/item/47014667",
    title: "Per E.D.L.I.C. – Apologia catholica : adversus libellos, declarationes, monita et consultationes factas, scriptas et editas a foederatis perturbatoribus pacis in regno Franciae",
  },
  {    
    url: "https://polona2.pl/item/47014659",
    title: "a R.P. Salvatore Bartolucio assisiate […] scoticae illustratore, fidelissime recognitae, […] locupletate, &amp; exornatae – F. Ioan. Dvns Scoti, Ordinis Minorvm […] Quaestiones Secundi Voluminis scripti Oxoniensis Super Sententias. T. 2",
  },
  {    
    url: "https://polona2.pl/item/47014665",
    title: "per reverendum patrem sacre theologie professorem in alma facultate theologie Parisiensi, fratrem Guillermum Pepin: reformati conventus Ebroycensis. Ordinis Fratrum predicatorum. Item aliud parvum Rosarium ab eodem editum continens sermones septem: intitulatum Salutate Mariam – Rosarium aureum mysticum nuper editum",
  },
  {    
    url: "https://polona2.pl/item/47014670",
    title: "Avthore R. P. Pavlo De Barry Societatis Jesv Gallice conscripta ; Nunc in vsum plurium Latinitate donata […] Interprete R. P. Michaele Cvvelier, eiusdem Societatis Jesv Sacerdote – Solitvdo Hagiophilae Sive Instrvctio Ad annua octo vel decem dierum Exercitia spiritualia vtiliter peragenda : Cum Meditationibus, Confiderationibus, Examinibus, &amp; spiritualibus Lectionibus, quae fieri eo tempore poterunt",
  },
  {    
    url: "https://polona2.pl/item/47014666",
    title: "De postremis motibus Galliae varia vtriusque partis scripta scitu dignissima : quorum catalogus tertia pagina exponitur. Omnia fere ex Gallico in Latinum sermonem, ad vsum eruditorum &amp; historiae studiosorum, fideliter conuersa. Addita est praefatio, qua scriptorum ratio, historia, &amp; analysis declaratur.",
  },
  {    
    url: "https://polona2.pl/item/47014669",
    title: "à R.P. Nicolao De Arnaya eiusdem Societatis : in compendium redactae, &amp; in septem partes diuisae : Ex Hispanico In Latinum sermonem traducta à M. M. – Lvdovici De Ponte […] Meditationes De Praecipvis Fidei Nostrae Mysteriis",
  },
  {    
    url: "https://polona2.pl/item/47014668",
    title: "Del […] Cesare Calderari […] – Concetti scritturali intorno al Miserere […]",
  },
  {    
    url: "https://polona2.pl/item/47183018",
    title: "Alantsee, Ambrosius – Tractatus qui intitulatur Fedus christianu[m] … .",
  },
  {    
    url: "https://polona2.pl/item/47248310",
    title: "Statuta prouincialia toti p[ro]uintie Gneznen[si] valentia …",
  },
  {    
    url: "https://polona2.pl/item/47247417",
    title: "Henricus de Langenstein – Secreta sacerdotu[m].",
  },
  {    
    url: "https://polona2.pl/item/47178780",
    title: "Petrus Ravennas – Sermones extraordinarij et pulcherrimi … et … [alia] opuscula … .",
  },
  {    
    url: "https://polona2.pl/item/47034694",
    title: "Biblia, Lat., cum additione Menardi monachi.",
  },
  {    
    url: "https://polona2.pl/item/47034991",
    title: "Cicero, Marcus Tullius (106-43 a. C.) – Opera. Ed. Alexander Minutianus. P. I-IV.",
  },
  {    
    url: "https://polona2.pl/item/47036702",
    title: "Servasanctus de Faenza – Sermones de tempore et de sanctis.",
  },
  {    
    url: "https://polona2.pl/item/47036119",
    title: "Osvaldus de Lasco – Sermones de sanctis Biga salutis intitulati.",
  },
  {    
    url: "https://polona2.pl/item/47248366",
    title: "Nicolaus de Błonie – Tractatus sacerdotalis de scramentis…",
  },
  {    
    url: "https://polona2.pl/item/47014663",
    title: "Raccolta da monsig. Pietro Galesani […] Con diligente osseruatione, &amp; imitatione de’piu nobili scrittori antichi latini […] Con il dittionaro latine nel fine […] di M. Cesare Mirani Calderino […] Settima editione, accresiuto esso tesoro, &amp; ampliato in piu de dua mila lochi […] per […] Francesco Besozzo – Dittionario overo Tesoro della Lingua, Volgare, Latina",
  },
  {    
    url: "https://polona2.pl/item/47014658",
    title: "R.P. Salvatore Bartolucio assisiate […] scoticae illustratore, fidelissime recognitae, […] locupletate, &amp; exornatae – F. Ioan, Duns Scoti, ordinis minorum […] Quaestiones Quatuor Voluminum scripti Oxoniensis Super Sententias. T. 1",
  },
  {    
    url: "https://polona2.pl/item/47183299",
    title: "Duns Scotus, Ioannes – Commentaria Doctoris Subtilis Ioannis Scoti in 12 li. Metaphisic[a]e Aristotelis…",
  },
  {    
    url: "https://polona2.pl/item/47014656",
    title: "Michelangelo Marin, religioso minimo – Virginia, ovvero la vergine cristiana : istoria siciliana, per servire dimodello alle donzelle che aspirano alla perfezione. Libro 3",
  },
  {    
    url: "https://polona2.pl/item/47014657",
    title: "Michelangelo Marin, religioso minimo – Virginia, ovvero la vergine cristiana: Istoria siciliana, per servire di modello alle donzelle che aspirano alla perfezione. Libro 4",
  },
  {    
    url: "https://polona2.pl/item/46867556",
    title: "Bonaventura, (s.) – De praeparatione ad missam.",
  },
  {    
    url: "https://polona2.pl/item/48038828",
    title: "Richardus de Sancto Victore. – De duodecim patriarchis. ",
  },
  {    
    url: "https://polona2.pl/item/47036536",
    title: "Servasanctus de Faenza – Sermones de tempore et de sanctis.",
  },
  {    
    url: "https://polona2.pl/item/47063931",
    title: "Angelus de Clavasio – Summa angelica de casibus conscientiae.",
  },
  {    
    url: "https://polona2.pl/item/47248102",
    title: "Burchardus, Johannes (ca 1450-1506) – Ordo misse…",
  },
  {    
    url: "https://polona2.pl/item/47063173",
    title: "Gerson, Ioannes – Opera etc. P. I-III, Inventarium.",
  },
  {    
    url: "https://polona2.pl/item/47247309",
    title: "Herolt, Ioannes – Sermones Discipuli de tempore et de sanctis cum Promptuario exemplorum et de Beata Virgine.",
  },
  {    
    url: "https://polona2.pl/item/47248646",
    title: "Abulkasim – Liber theoricae nec non practicae Alsaharavii …",
  },
  {    
    url: "https://polona2.pl/item/47014660",
    title: "a R.P. Salvatore Bartolucio assisiate […] scoticae illustratore, fidelissime recognitae, […] locupletate, &amp; exornatae – F. Ioan, Duns Scoti, ordinis minorum […] Quaestiones Quatuor Voluminum scripti Oxoniensis Super Sententias. T. 3",
  },
  {    
    url: "https://polona2.pl/item/47014638",
    title: "przez J. X. Augustyna Lipińskiego, Kanonika katedralnego Płockiego wyznaczonego Wizytatora – Kazanie podczas wizyty generalnéy w Łomzie u WW. JJ. PP. Benedyktynek miane",
  },
  {    
    url: "https://polona2.pl/item/47028060",
    title: "Gerson, Ioannes – Opera etc. P. I-III, Inventarium.",
  },
  {    
    url: "https://polona2.pl/item/47063999",
    title: "Gerson, Ioannes – Opera etc. P. I-III, Inventarium.",
  },
  {    
    url: "https://polona2.pl/item/47014629",
    title: "a Martino Baronio […] – Vitae, Gesta et Miracvla Beati Esaiae Poloni Cracouiensis […] : ex diuersis Authorum fide dignorum fragmentis excerpta [et] in Catalogo Sanctorum Regni Poloniae Patronorum seriae est congesta [et] nunc primum in lucem Aedita : Avthoritate […] Bernardi Macieiowski […] 1606",
  },
  {    
    url: "https://polona2.pl/item/47035197",
    title: "Bonifacius VIII (papa) – Liber sextus Decretalium.",
  },
  {    
    url: "https://polona2.pl/item/47035443",
    title: "Clemens V (papa) – Constitutiones cum Extravagantibus XX, ex recensione Alexandri de Nevo.",
  },
  {    
    url: "https://polona2.pl/item/47014664",
    title: "Auct. Caes. Calderino Mirano Veronensi […] – Dictionarium : sive thesauri lingvae Latinae et omnium a vocibus Latinis incipientium dictionariorum compendium : Italicis etiam, &amp; praecipue D. Petri Galesini […] thesauro accommodatum […]"
  },
];

