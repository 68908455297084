import React from "react";
import { useSelector } from "react-redux";
import { Translate } from "react-redux-i18n";
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles/screenContentStylesheet';
import Colors from '../shared/styles/Colors'

import Animation from "../../assets/mp4/church-animation.mp4";

import Languages from "./Languages";
import HomeNav from "./HomeNav";
import VisualImpared from "./VisualImpared";
import { Ornament } from "../shared/Ornament";

const useStyles = makeStyles(() => styles());

const ScreenContent = () => {
  const visualFacs = useSelector(state => state.data.visualFacilities);
  
  const classes = useStyles({ colors: Colors(), animationOpacity: visualFacs });

  return(
    <div className={classes.screenContent}>
      <video className={classes.animation} autoPlay muted>
        <source src={Animation} type='video/mp4'></source>
      </video>
      <Ornament />
      <div className={classes.contentElements}>
        <VisualImpared />
        <Languages />
        <div className={classes.mainTitles}>
          <div className={classes.firstTitle}>
            <div className={classes.titlePart}><Translate value="home.title_0"/>&nbsp;</div>
            <div className={classes.titlePart}><Translate value="home.title_1"/></div>
          </div>
          <div className={classes.secondTitle}><Translate value="home.title_2"/></div>
        </div>
        <HomeNav />
      </div>
    </div>
  )
}
export default ScreenContent;