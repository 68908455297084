import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import './config/globalStyles.scss';
import './App.scss';
import translationsObject from './config/translationObject';
import { store } from './store/store';
import Home from './components/Home/Home';
import BookCollection from './components/BookCollection/BookCollection';
import Basilica from './components/Basilica/Basilica';
import Galleries from './components/Galleries/Galleries';
import NoPath from './components/NoPath';
import { getData, sendKioskStats } from './store/actions/dataActions';
import { fetchInterval } from './config/options';

const App = () => {
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translationsObject));
  store.dispatch(setLocale('0')); //set english lang, id = 0
  store.dispatch(getData()); //load all data from api
  store.dispatch(sendKioskStats()); //send info about successful kiosk restart
  setInterval(() => store.dispatch(getData()), fetchInterval);

  let now = new Date();
  let millisTill9 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0, 0) - now;
  let millisTill12 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 12, 0, 0, 0) - now;
  let millisTill15 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0, 0, 0) - now;
  let millisTill21 = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 21, 0, 0, 0) - now;
  millisTill9 > 0 && setTimeout(() => store.dispatch(sendKioskStats()), millisTill9);
  millisTill12 > 0 && setTimeout(() => store.dispatch(sendKioskStats()), millisTill12);
  millisTill15 > 0 && setTimeout(() => store.dispatch(sendKioskStats()), millisTill15);
  millisTill21 > 0 && setTimeout(() => store.dispatch(sendKioskStats()), millisTill21);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Route render={({location}) => {
          return(
            <TransitionGroup>
              <CSSTransition
                key={location.pathname}
                classNames="fade"
                timeout={{
                  appear: 0,
                  enter: 500,
                  exit: 500,
                }}
              >
                <Switch location={location}>
                  <Route path="/" component={Home} exact />
                  <Route path="/book-collection" component={BookCollection} exact />
                  <Route path="/basilica" component={Basilica} exact/>
                  <Route path="/galleries" component={Galleries} exact/>
                  <Route component={NoPath} />
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}}/>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
