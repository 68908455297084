import React from "react";
import { Ornament } from '../shared/Ornament';
import CategoryDescription from './Description/CategoryDescription';
import CategoryObjects from './CategoryObjects';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../shared/styles/screenContentStylesheet';

const useStyles = makeStyles((props) => (styles(props)));

const ScreenContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.screenContent}>
      <Ornament />
      <CategoryDescription />
      <CategoryObjects />
    </div>
  )
}
export default ScreenContent;
