import React from "react";
import { useDispatch } from "react-redux";
import { Translate } from "react-redux-i18n";
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles/visualImparedStylesheet';
import Colors from '../shared/styles/Colors';
import Fonts from '../shared/styles/Fonts';

import { switchVisualFacilities } from "../../store/actions/dataActions";

import ImgEye from "../../assets/svg/icn_eye.svg";

const useStyles = makeStyles(() => styles());

const VisualImpared = () => {
  const dispatch = useDispatch();

  const classes = useStyles({ 
    colors: Colors(),
    fonts: Fonts(),
  });

  return(
    <div className={classes.visuallyImpairedContainer}>
      <div className={classes.visuallyImpaired} onClick={
        () => dispatch(switchVisualFacilities())
      }>
        <img className={classes.imgEye} src={ImgEye} alt=""/>
        <div className={classes.textContainer}>
          <div className={classes.marginTop}><Translate value="home.eye_1"/></div>
          <div className={classes.marginTop}><Translate value="home.eye_2"/></div>
        </div>
      </div>
    </div>
  )
}
export default VisualImpared;