import { apiServerPath, loginPath, offlineApi } from "../../config/path";

import {
  REQUEST_DATA,
  RESPONSE_DATA,
  CLEAR_DATA,
  SWITCH_VISUAL_FACILITIES,
  REGISTER_KIOSK,
  ADD_MODAL_ENTRY,
  ADD_GALLERIES_ENTRY,
  ADD_BOOK_COLLECTIONS_ENTRY,
} from "./actionTypes";

import translationsObject from "../../config/translationObject";

import axios from "axios";

/**
 |--------------------------------------------------
 | GET DATA FROM API
 |--------------------------------------------------
 */
export const getData = () => (dispatch) => {
  dispatch(requestGetData());
  Object.keys(translationsObject).forEach((langId) => {
    axios
      .get(apiServerPath(langId), {
        headers: {
          Authorization: "Best ProxyServer!",
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(responseGetData(res.data.data, langId));
      })
      .catch((error) => {
        console.log(
          'Error of loading lang "' + langId + '" data from api ' + error
        );

        axios
          .get(offlineApi(langId), {
            "Content-Type": "application/json",
          })
          .then((res) => {
            dispatch(responseGetData(res.data.data, langId));
          })
          .catch((error) => {
            console.log(
              'Error of loading lang "' +
                langId +
                '" data from local api ' +
                error
            );
          });
      });
  });
};

const requestGetData = () => {
  return {
    type: REQUEST_DATA,
  };
};

const responseGetData = (data, langId, nameOfType) => {
  return {
    type: RESPONSE_DATA,
    payload: {
      data,
      langId,
      nameOfType,
    },
  };
};

/**
 |--------------------------------------------------
 | CLEAR DATA
 |--------------------------------------------------
 */

export const clearData = () => ({
  type: CLEAR_DATA,
});

/**
 |--------------------------------------------------
 | SWITCH VISUAL FACILITIES
 |--------------------------------------------------
 */

export const switchVisualFacilities = () => ({
  type: SWITCH_VISUAL_FACILITIES,
});

/**
 |--------------------------------------------------
 | SEND INFO
 |--------------------------------------------------
 */

const registerKiosk = () => {
  return {
    type: REGISTER_KIOSK,
  };
};

export const sendKioskStats = () => (dispatch, getState) => {
  const { imageModalEntries, galleryEntries, bookCollectionEntries } =
    getState().data;
  const now = new Date();

  axios
    .post(loginPath, {
      message:
        `imageModal: ${imageModalEntries}, ` +
        `gallery: ${galleryEntries}, ` +
        `books: ${bookCollectionEntries}, `+
        `time: ${now.getHours()}:${now.getMinutes()}, `,
      time: Date.now(),
    })
    .then((res) => {
      console.log("Kiosk registered");
      console.log(res.data);
      dispatch(registerKiosk());
    })
    .catch((error) => {
      console.log("Error of kiosk registration");
      console.log(error);
      dispatch(registerKiosk());
    });
};

export const addModalEntry = () => {
  return {
    type: ADD_MODAL_ENTRY,
  };
};

export const addGalleriesEntry = () => {
  return {
    type: ADD_GALLERIES_ENTRY,
  };
};

export const addBookCollectionsEntry = () => {
  return {
    type: ADD_BOOK_COLLECTIONS_ENTRY,
  };
};
