// Translations texts for { loadTranslations } from 'react-redux-i18n';

const translationsObject = {
  "0": {
    home: {
      title_0: "The Franciscan",
      title_1: "Basilica and Monastery",
      title_2: "of St. Francis of Assisi",
      eye_1: "facilities for",
      eye_2: "the visually impaired",
      book_coll_1: "historic book",
      book_coll_2: "collection",
      galleries_1: "cloisters",
      galleries_2: "",
      basilica_1: "basilica and",
      basilica_2: "oratories",
    },
    book_coll: {
      title: "Historic book collection",
    },
    galleries: {
      title: "Cloisters",
    },
    basilica: {
      title: "Basilica and oratories"
    },
    categories: {
      '21':"East Wing",
      '29':"South Wing",
      '28':"West Wing",
      '16':"Cloisters",
    },
    galleriesTabs: {
      bishopsGallery: "Gallery of bishops",
      frescoes: "Frescoes",
      epitaphsAndPlaques: "Epitaphs and plaques",
    },
    return: "Back",
  },
  "1": {
    home: {
      title_0: "Klasztor i Bazylika",
      title_1: "oo. Franciszkanów",
      title_2: "św. Franciszka z Asyżu",
      eye_1: "udogodnienia",
      eye_2: "dla słabowidzących",
      book_coll_1: "Księgozbiór",
      book_coll_2: "zabytkowy",
      galleries_1: "Krużganki",
      galleries_2: "",
      basilica_1: "bazylika",
      basilica_2: "i kaplice",
    },
    book_coll: {
      title: "Księgozbiór zabytkowy",
    },
    galleries: {
      title: "Krużganki",
    },
    basilica: {
      title: "Bazylika i kaplice"
    },
    categories: {
      '21':"Skrzydło wschodnie",
      '29':"Skrzydło południowe",
      '28':"Skrzydło zachodnie",
      '16':"Krużganki",
    },
    galleriesTabs: {
      bishopsGallery: "Galeria biskupów",
      frescoes: "Freski",
      epitaphsAndPlaques: "Epitafia i tablice",
    },
    return: "Powrót",
  },
  "2": {
    home: {
      title_0: "L'église et le Monastère",
      title_1: "Franciscain",
      title_2: "Saint-François d'Assise",
      eye_1: "installations",
      eye_2: "pour les malvoyants",
      book_coll_1: "collection de livres",
      book_coll_2: "historiques",
      galleries_1: "le cloitre",
      galleries_2: "",
      basilica_1: "basilique",
      basilica_2: "et chapelles",
    },
    book_coll: {
      title: "Collection de livres historiques",
    },
    galleries: {
      title: "Le cloitre",
    },
    basilica: {
      title: "Basilique et chapelles"
    },
    categories: {
      '21':"Skrzydło wschodnieFR",
      '29':"Skrzydło południoweFR",
      '28':"Skrzydło zachodnieFR",
      '16':"KrużgankiFR",
    },
    galleriesTabs: {
      bishopsGallery: "Galerie des évêques",
      frescoes: "Fresques",
      epitaphsAndPlaques: "Épitaphes et plaques",
    },
    return: "Retour",
  },
  "3": {
    home: {
      title_0: "Die Basilika",
      title_1: "und das Franziskanerkloster",
      title_2: "des heiligen Franz von Assisi",
      eye_1: "erleichterungen",
      eye_2: "für sehbehinderte",
      book_coll_1: "die historische",
      book_coll_2: "bibliothek",
      galleries_1: "der Kreuzgang",
      galleries_2: "",
      basilica_1: "die Basilika",
      basilica_2: "und Kapellen",
    },
    book_coll: {
      title: "Die Historische Bibliothek",
    },
    galleries: {
      title: "Der Kreuzgang",
    },
    basilica: {
      title: "Die Basilika und Kapellen"
    },
    categories: {
      '21':"Skrzydło wschodnieDE",
      '29':"Skrzydło południoweDE",
      '28':"Skrzydło zachodnieDE",
      '16':"KrużgankiDE",
    },
    galleriesTabs: {
      bishopsGallery: "Galerie der Bischöfe",
      frescoes: "Die Fresken",
      epitaphsAndPlaques: "Epitaphien und Plaques",
    },
    return: "Zurück",
  },
  "4": {
    home: {
      title_0: "Basilica e Convento",
      title_1: "dei Francescani",
      title_2: "di San Francesco d'Assisi",
      eye_1: "facilitazioni",
      eye_2: "per ipovedenti",
      book_coll_1: "una collezione",
      book_coll_2: "di libri storici",
      galleries_1: "chiostro",
      galleries_2: "",
      basilica_1: "basilica",
      basilica_2: "e cappelle",
    },
    book_coll: {
      title: "Una collezione di libri storici",
    },
    galleries: {
      title: "Chiostro",
    },
    basilica: {
      title: "Basilica e cappelle"
    },
    categories: {
      '21':"Skrzydło wschodnieIT",
      '29':"Skrzydło południoweIT",
      '28':"Skrzydło zachodnieIT",
      '16':"KrużgankiIT",
    },
    galleriesTabs: {
      bishopsGallery: "Galleria dei vescovi",
      frescoes: "Affreschi",
      epitaphsAndPlaques: "Epitaffi e targhe",
    },
    return: "Indietro",
  },
  "5": {
    home: {
      title_0: "Базиліка і Монастир",
      title_1: "Францисканців",
      title_2: "св. Франциска Асcізького",
      eye_1: "допомога для",
      eye_2: "людей з вадами зору",
      book_coll_1: "iсторична",
      book_coll_2: "колекція книг",
      galleries_1: "галерея",
      galleries_2: "",
      basilica_1: "базиліка",
      basilica_2: "та каплиць",
    },
    book_coll: {
      title: "Історична колекція книг",
    },
    galleries: {
      title: "Галерея",
    },
    basilica: {
      title: "Базиліка та каплиць"
    },
    categories: {
      '21':"Skrzydło wschodnieUA",
      '29':"Skrzydło południoweUA",
      '28':"Skrzydło zachodnieUA",
      '16':"KrużgankiUA",
    },
    galleriesTabs: {
      bishopsGallery: "Галерея єпископів",
      frescoes: "Фрески",
      epitaphsAndPlaques: "Епітафії та помінальні дошки",
    },
    return: "Назад",
  },
  "6": {
    home: {
      title_0: "Францисканская Базилика",
      title_1: "и Mонастырь",
      title_2: "святого Франциска Ассизского",
      eye_1: "удобства",
      eye_2: "для слабовидящих",
      book_coll_1: "историческая",
      book_coll_2: "коллекция книг",
      galleries_1: "крытая аркада",
      galleries_2: "",
      basilica_1: "базилика",
      basilica_2: "и часовен",
    },
    book_coll: {
      title: "Историческая коллекция книг",
    },
    galleries: {
      title: "Крытая аркада",
    },
    basilica: {
      title: "Базилика и часовен"
    },
    categories: {
      '21':"Skrzydło wschodnieRU",
      '29':"Skrzydło południoweRU",
      '28':"Skrzydło zachodnieRU",
      '16':"KrużgankiRU",
    },
    galleriesTabs: {
      bishopsGallery: "Галерея епископов",
      frescoes: "Фрески",
      epitaphsAndPlaques: "Эпитафы и мемориальные доски",
    },
    return: "Назад", 
  },
};
export default translationsObject;