import { useSelector  } from "react-redux";

const Colors = () => {
  const visualFacs = useSelector(state => state.data.visualFacilities);
  const colors = {
    background: (visualFacs===true) ?'rgba(0,0,0,1)':'rgba(30,57,87,1)',
    font: (visualFacs===true) ?'rgba(240, 255, 0, 1)':'rgba(223, 178, 117, 1)',
    backgroundShadow: (visualFacs===true) ?'rgba(255, 255, 255, 0.3)':'rgba(0, 0, 0, 0.15)',
    border: (visualFacs===true) ?'yellow':'transparent',
    borderList: (visualFacs===true) ?'white':'transparent',
    modalBackground: (visualFacs===true) ?'rgba(0,0,0,1)':'rgba(255,255,255,1)',
    modalFont: (visualFacs===true) ?'rgba(255,255,255,1)':'rgba(30,57,87,1)',
    tongue: (visualFacs===true) ?'rgba(0,0,0,1)':'rgba(30,57,87,1)',
    selected: (visualFacs===true) ?'yellow':'inherit',
    primary: (visualFacs===true) ?'#ffff00':'#1e3957',
    secondary: (visualFacs===true) ?'#ffff00':'#dfb275',

    tabsOpacity: (visualFacs===true) ?'1':'0.7',
    outlineWidth: (visualFacs===true) ?'0.74vmin':'0.37vmin',
  }
  return (colors);
}

export default Colors;
