export const modalPaperStylesheet = (props) => {
  return {
    closeContainer: {
      position: 'absolute',
      height:'6.67vmin',
      width:'6.67vmin',
      right: '0',
      top: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    close: props => ({
      color: `${props.colors.modalFont}`,
      fontSize: '4.44vmin',
    }),
    modalDescription: props => ({
      width: '44.08%',
      padding: '6.67vmin 1.97% 6.67vmin 3.95%',
      display: 'flex',
      flexDirection: 'column',
    }),
    modalText: {
      // height: '62.62%',
      display: 'flex',
      flexDirection: 'column',
    },
    modalHeader: props => ({
      boxShadow: `0px 0px 2vmin 2vmin ${props.colors.modalBackground}`,
      zIndex: '100',
    }),
    modalTitle: props => ({
      color: `${props.colors.font}`,
      fontFamily: 'cormorant-garamond-bold',
      fontSize: '5.55vmin',
    }),
    modalLocation: props => ({
      color: `${props.colors.modalFont}`,
      fontFamily: 'metropolis-bold',
      fontSize: '1.48vmin',
      textTransform: 'uppercase',
      // lineHeight: '274%',
      display: 'flex',
      alignItems: 'center',
      paddingTop: '3.33vmin',
    }),
    modalIntroduction: props => ({
      color: `${props.colors.modalFont}`,
      fontFamily: 'metropolis-regular',
      fontSize: '1.67vmin',
      padding: '3.33vmin 0 2.22vmin 0',
      lineHeight: '175%',
      textAlign: 'justify',
      overflow: 'auto',
      width: '100%',
      paddingRight: '6.50%',
      zIndex: '50',
      '&::-webkit-scrollbar-track': props => ({
        backgroundColor: `${props.colors.backgroundShadow}`,
      }),
      '&::-webkit-scrollbar': {
        width: '1.54%',
      },
      '&::-webkit-scrollbar-thumb': props => ({
        backgroundColor: `${props.colors.font}`,
      }),
    }),
    bottomShadow: props => ({
      boxShadow: `0px 0px 2vmin 2vmin ${props.colors.modalBackground}`,
      zIndex: '100',
    }),
    galleryTitle: props => ({
      color: `${props.colors.modalFont}`,
      fontFamily: 'metropolis-extra-bold',
      fontSize: '1.67vmin',
      padding: '4.44vmin 0 4.44vmin 0',
    }),
    tilesContainer: {
      display: 'flex',
      marginLeft: '-3.33vmin',
    },
    tilesSlider: {
      display: 'flex',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      position: 'relative',
      paddingLeft: '3.33vmin',
    },
    tileSelected: props => ({
      height: '18.52vmin',
      width: '18.52vmin',
      top: '0',
      outline: `${props.colors.outlineWidth} solid ${props.colors.font}`,
      outlineOffset: `-${props.colors.outlineWidth}`,
      zIndex: '50',
      position: 'absolute',
      transition: 'left 0.5s',
    }),
    tileContainer: {
      marginRight: '2.04vmin',
      display: 'flex',
    },
    tile: props => ({
      objectFit: 'cover',
      height: '18.52vmin',
      width: '18.52vmin',
      alignItems: 'center',
      justifyContent: 'center',
      outline: `0.37vmin solid ${props.colors.borderList}`,
      outlineOffset: '-0.37vmin',
    }),
    sliderEnd:{
      height: '100%',
      minWidth: '5vmin',
    },
    sliderShadow: props => ({
      height: '100%',
      width: '0px',
      // boxShadow: `0px 0px 2.22vmin 3.33vmin ${props.colors.modalBackground}`,
      boxShadow: `0px 0px 1.5vmin 2.22vmin ${props.colors.modalBackground}`,
      zIndex: '100',
    }),
    modalRight: {
      width: '91.23%',
      display: 'flex',
      padding: '6.67vmin 3.95% 6.67vmin 4.82%',
    },
    modalImgCont: {
      width: '100%',
      height: '100%',
      position: 'relative',
    },
          /* Value 'contain' of objectFit makes impossible to border an image.
          *  To stretch image for whole area change:
          *  objectFit on 'contain' and maxWidth->width, maxHeight->height
          */     
    modalImage: props => ({
      // objectFit: 'contain',
      // width: '100%',
      // height: '100%',
      objectFit: 'fill',
      maxWidth: '100%',
      maxHeight: '100%',
      outline: `0.37vmin solid ${props.colors.border}`,
      outlineOffset: '-0.37vmin',
      position: 'absolute',
      margin: 'auto',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      zIndex: '1',
    }),
  }
}