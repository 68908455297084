const screenContentStylesheet = (props) => {
  return {
    screenContent: {
      position: 'relative',
      width: 'calc(100% - 5vmax)',
    },
    contentElements: {
      position: 'absolute',
      top: '1.25vmax',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    animation: props => ({
      position: 'absolute',
      width: '100%',
      maxHeight: '100%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: `${(!props.animationOpacity)?'1':'0'}`,
      transition: '0.5s opacity',
      transitionDelay: `${(props.animationOpacity)?'0s':'0.5s'}`,
    }),
    mainTitles: {
      width: '100%',
      padding: '6.67vmin',
    },
    firstTitle: props => ({
      fontFamily: 'cormorant-garamond-bold',
      fontSize: '7.41vmin',
      textAlign: 'center',
      width: '100%',
      color: `${props.colors.font}`,
      transition: '0.5s color',
    }),
    titlePart: {
      display: 'inline-block',
    },
    secondTitle: {
      marginTop: '4.44vmin',
      fontFamily: 'cormorant-medium-italic',
      fontSize: '5.19vmin',
      textAlign: 'center',
      color: 'white',
      width: '100%',
    }
  }
}
export default screenContentStylesheet;