import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import './transition.scss';

export const ObjectImage = (props) => {
  return(
    <div className={props.classes.modalRight}>
      <div className={props.classes.modalImgCont}>
        <TransitionGroup>
          <CSSTransition
            key={props.src}
            classNames="imgTransition"
            timeout={{
              appear: 0,
              enter: 500,
              exit: 500,
            }}
          > 
            <img 
              className={props.classes.modalImage}
              src={props.src} alt=""
              style={{
                backgroundImage: `url(${props.src_sm})`,
                backgroundSize: 'cover'
              }}
            />
          </CSSTransition>
        </TransitionGroup>
      </div>
    </div>
  )
}