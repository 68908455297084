const screenContentStylesheet = (props) => {
  return(
    {
      screenContent: {
        position: 'relative',
        width: 'calc(100% - 5vmax)',
        display: 'flex',
        flexDirection: 'row',
      },
    }
  )
}
export default screenContentStylesheet;