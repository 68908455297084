import React from "react";
import Button from '@material-ui/core/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';

export const ObjectTile = (props) => {
  const classes = props.classes;
  return(
    <div className={classes.objectButton}>
      <Button color="primary">
        <div className={classes.imgContainer} onClick={props.handleOpen}>
          <LazyLoadImage
            alt=""
            src={props.object.image_sm}
            effect="opacity" 
            className={classes.image}
            width='100%' 
            height='100%' />
        </div>
      </Button>
    </div>
  )
}