const categoryDescriptionStylesheet = (props) => {
  return(
    {
      mainTitle: props => ({
        fontFamily: 'cormorant-garamond-bold',
        fontSize: '7.41vmin',
        color: props.colors.font,
        backgroundColor: props.colors.background,
        // boxShadow: `0px 1vmin 2.78vmin 2.78vmin ${props.colors.background}`,
        boxShadow: `0px 0.5vmin 2vmin 2vmin ${props.colors.background}`,
        zIndex: '200',
        marginRight: '2%',
        // marginLeft: '-72px',
        // paddingLeft: '72px',
        textAlign: 'left',
      }),
      boldedText: {
        fontFamily: 'cormorant-garamond-bold',
        fontSize: '3.33vmin',
        lineHeight: '130%',
      },
      textContainer: props => ({
        fontFamily: 'metropolis-regular',
        fontSize: '1.67vmin',
        height: '86.75%',
        width: '100%',
        overflow: 'auto',
        // paddingRight: '5.26%',
        paddingRight: '43px',
        // paddingTop: '4.44vmin',
        paddingTop: '1vmin',
        zIndex: '100',
        lineHeight: '174%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '4.5vmin',
        marginLeft: '-72px',
        paddingLeft: '72px',
        '&::-webkit-scrollbar-track': props => ({
          backgroundColor: `${props.colors.backgroundShadow}`,
        }),
        '&::-webkit-scrollbar': {
          width: '1.54%',
        },
        '&::-webkit-scrollbar-thumb': props => ({
          backgroundColor: `${props.colors.font}`,
        }),
      }),
      imgToLeft: props => ({
        float: 'left', 
        width: '50%', 
        margin: '2.22vmin 2.22vmin 1.11vmin 0',
        border: `0.37vmin solid ${props.colors.borderList}`,
      }),
      imgToRight: props => ({
        float: 'right', 
        width: '50%', 
        margin: '2.22vmin 0 1.11vmin 2.22vmin',
        border: `0.37vmin solid ${props.colors.borderList}`,
      }),
      bottomEnd: {
        width: '100%',
        height: '4.5vmin',
      },
      bottomTextShadow: props => ({
        boxShadow: `0px -0.5vmin 4vmin 4vmin ${props.colors.background}`,//red`,//
        zIndex: '100',
        marginRight: '4vmin',
        marginBottom: '1vmin',
        marginLeft: '1vmin',
      }),
      categoryDescription: {
        // padding: '6.67vmin 1.48% 5.67vmin 3.95%',
        padding: '6.67vmin 27px 5.67vmin 72px',
        textAlign: 'justify',
        fontSize: '1.67vmin',
        fontFamily: 'cormorant-garamond-bold',
        color: '#ffffff',
        width: '44.58%',
        display: 'flex',
        flexDirection: 'column',
      },

      tilesContainer: {
        display: 'flex',
        marginLeft: '-30px',
        marginBottom: '40px',
      },
      tilesSlider: {
        display: 'flex',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        position: 'relative',
        paddingLeft: '30px',
      },
      tileContainer: {
        marginRight: '2.04vmin',
        display: 'flex'
      },
      tile: props => ({
        objectFit: 'cover',
        height: '18.52vmin',
        width: '18.52vmin',
        alignItems: 'center',
        justifyContent: 'center',
        outline: `0.37vmin solid ${props.colors.borderList}`,
        outlineOffset: '-0.37vmin',
        '&:hover': {
          outline: `0.74vmin solid ${props.colors.selected}`,
          outlineOffset: '-0.74vmin',
        },
      }),
      sliderEnd:{
        height: '100%',
        minWidth: '20px',
      },
      sliderShadow: props => ({
        height: '100%',
        width: '0px',
        // boxShadow: `0px 0px 2.22vmin 3.33vmin ${props.colors.modalBackground}`,
        boxShadow: `0px 0px 1.5vmin 2.22vmin ${props.colors.background}`,
        zIndex: '100',
      })
    }
  )
}
export default categoryDescriptionStylesheet;