import React from "react";
import { NavLink } from "react-router-dom";

const NoPath = () => {
  return (
    <>
      <div>Strona nie istnieje!</div>
      <NavLink to="/">Home</NavLink>
    </>
  )
}

export default NoPath;