const tabsStylesheet = (props) => {
  return (
    {
      tabsLeftShadow: props=> ({
        position: 'absolute',
        left: '7.31vmin',
        top: '13.33vmin',
        bottom: '6.67vmin',
        boxShadow: `0 3vmin 1vmin 1.2vmin ${props.colors.background}`,
        zIndex: '100',
      }),
      objectsList: {
        display: 'flex',
        flexDirection: 'column',
        padding: '6.67vmin 3.95% 6.67vmin 4.33%',
        width: '41.72%',
        position: 'relative',
      }
    }
  )
}
export default tabsStylesheet;