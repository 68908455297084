import Colors from '../../shared/styles/Colors'
/*
 * It overrides default Material-UI theme.
 */
const MuiTabs = () => {
  const colors = Colors();
  return {
    overrides: {
      MuiPaper: {
        elevation4: {
          boxShadow: 'none !important',
        }, 
        root: {
          minHeight: '6.67vmin',
        }
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: "transparent"
        },
      },
      MuiTab: {
        root: {
          padding: '6px 6px',
          minWidth: '33.33% !important',
          fontFamily: 'cormorant-garamond-bold',
          fontSize: '1.67vmin',
          textTransform: 'none',
        },
        wrapper: {
          fontFamily: 'metropolis-extra-bold',
          fontSize: '1.58vmin', //polish version text is wrapped when 1.67
          textTransform: 'uppercase',
        },
        textColorInherit: {
          opacity: `${colors.tabsOpacity}`,
          width: "33%",
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: `${colors.font}`,
          height: '0.37vmin',
        },
        root: {
          height: '100%',
        },
        flexContainer: {
          height: '94.44%',
          borderBottom: `0.37vmin solid ${colors.backgroundShadow}`,
        },
        
      },
      MuiSelected: {
        color: 'red',
      },
      MuiBox: {
        root: {
          display: 'flex',
          left: '0',
          right: '0',
        }
      },
      MuiTypography :
      {
        root: {
          flex: '1 1 auto',
        },
      },
      MuiButtonBase : {
        root: {
          '&.Mui-selected': {
            color: `${colors.selected}`
          },
        }
      }
    }
  }
}
export default MuiTabs;