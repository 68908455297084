import React, { useState } from "react";
import { Translate } from "react-redux-i18n";
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles/screenContentStylesheet';
import Colors from '../shared/styles/Colors';
import { BooksList } from '../../config/booksList';
import Book from './Modal';
import { Ornament } from "../shared/Ornament";

const useStyles = makeStyles(() => styles());

const ScreenContent = () => {
  const classes = useStyles({ colors: Colors() });
  const [open, setOpen] = useState(false);
  const [bookUrl, setBookUrl] = useState(BooksList[0].url);
  const [iframeLoad, setIframeLoad] = useState(false);

  const handleOpen = (url) => {
    setBookUrl(url);
    setTimeout(() => { setOpen(true) }, 250);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return(
    <div className={classes.screenContent}>
      <Ornament />
      <div className={classes.mainTitle}><Translate value="book_coll.title" /></div>
      
        <ul className={classes.listContainer}>
          <br />
          {BooksList.map((object, index) => (
          <li key={index} className={classes.li} 
            onClick={() => {
              setIframeLoad(true);
              handleOpen(object.url);
            }}>
            {object.title}
          </li>))}
          <div className={classes.bottomListSpace}></div>
        </ul>
        <div className={classes.bottomShadow}></div>
        <Book 
          open={open} 
          handleClose={handleClose} 
          bookUrl={bookUrl} 
          iframeLoad={iframeLoad}
          setIframeLoad={setIframeLoad} />
    </div>
  )
}
export default ScreenContent;