const imgSize = "20.19vmin";

const modalStylesheet = (props) => {
  return {
    modal: {
      display: 'flex',
      justifyContent: 'center',
    },
    paper: props => ({
      display: 'flex',
      backgroundColor: `${props.colors.modalBackground}`,
      boxShadow: `0px 0px 1.85vmin 0.46vmin ${props.colors.backgroundShadow}`,
      outline: 'none',
      width: '95vmax',
      marginTop: '4.44vmin',
      marginBottom: '4.44vmin',
      position: 'relative',
    }),
    
    /* In-tabs tiles */
    singleObject: {
      display: 'flex',
      flexDirection: 'column',
      width: imgSize,
      margin: '1.67vmin 1.67vmin 1.67vmin 1.67vmin',
      padding: '0',
    },
    objectButton: {
      display: 'flex',
      flexDirection: 'column',
    },
    imgContainer: {
      width: '100%',
      height: imgSize,
    },
    image: props => ({
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      outline: `0.37vmin solid ${props.colors.borderList}`,
      outlineOffset: '-0.37vmin',
      '&:hover': {
        outline: `0.74vmin solid ${props.colors.selected}`,
        outlineOffset: '-0.74vmin',
      },
    }),
    imgTitle: props => ({
      color: 'white',
      fontFamily: 'metropolis-bold',
      fontSize: '1.67vmin',
      lineHeight: '1.67vmin',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',
      overflow: 'hidden',
      height: `3.34vmin`,
      margin: '1vmin 0 1vmin',
      display: '-webkit-box',
      '-webkit-line-clamp': '2',
      '-webkit-box-orient': 'vertical',
      textTransform: 'none',
    }),
  }
}
export default modalStylesheet;