const screenContentStylesheet = (props) => {
  return(
    {
      screenContent: {
        position: 'relative',
        width: 'calc(100% - 5vmax)',
        display: 'flex',
        flexDirection: 'column',
      },
      mainTitle: props => ({
        display: 'flex',
        fontFamily: 'cormorant-garamond-bold',
        fontSize: '7.41vmin',
        color: props.colors.font,
        margin: '6.67vmin 7.9% 0 3.95%',
        backgroundColor: `${props.colors.background}`,
        boxShadow: `0px 1.5vmin 2.78vmin 2.78vmin ${props.colors.background}`,
        zIndex: '100',
      }),
      listContainer: props => ({
        padding: '0 3.95% 0 0',
        margin: '0 3.95% 0 3.95%',
        color: 'white',
        fontSize: '3.33vmin',
        listStyleType: 'none',
        overflow: 'auto',
        '&::-webkit-scrollbar-track': props => ({
          backgroundColor: `${props.colors.backgroundShadow}`,
        }),
        '&::-webkit-scrollbar': {
          width: '0.8%',
        },
        '&::-webkit-scrollbar-thumb': props => ({
          backgroundColor: `${props.colors.font}`,
          height: '15vh',
        }),
      }),
      li: props => ({
        borderBottom: `3px double ${props.colors.font}`,
        lineHeight: '200%',
      }),
      bottomShadow: props => ({
        boxShadow: `0px -0.5vmin 4vmin 4vmin ${props.colors.background}`,
        margin: '0 7.9% 6.67vmin 3.95%',
        zIndex: '100',
      }),
      bottomListSpace: {
        height: '5vmin',
      },
    }
  )
}
export default screenContentStylesheet;