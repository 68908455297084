import {
  REQUEST_DATA,
  RESPONSE_DATA,
  CLEAR_DATA,
  SWITCH_VISUAL_FACILITIES,
  REGISTER_KIOSK,
  ADD_MODAL_ENTRY,
  ADD_GALLERIES_ENTRY,
  ADD_BOOK_COLLECTIONS_ENTRY
} from '../actions/actionTypes.js';

const initialState = {
  isLoadingData: false,
  visualFacilities: false,
  imageModalEntries: 0,
  galleryEntries: 0,
  bookCollectionEntries: 0,
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_DATA:
      return {
        ...state,
        isLoadingData: true,
      };
    case RESPONSE_DATA:
      return {
        ...state,
        ["lang"+action.payload.langId]: action.payload.data,
        isLoadingData: false,
      };
    case CLEAR_DATA: 
      return {
        ...initialState,
        visualFacilities: state.visualFacilities,
      };
    case SWITCH_VISUAL_FACILITIES:
      return {
        ...state,
        visualFacilities: !state.visualFacilities,
      };
    case REGISTER_KIOSK:
      return state;
    case ADD_MODAL_ENTRY: 
      return {
        ...state,
        imageModalEntries: state.imageModalEntries + 1,
      };
    case ADD_GALLERIES_ENTRY: 
      return {
        ...state,
        galleryEntries: state.galleryEntries + 1,
      };
    case ADD_BOOK_COLLECTIONS_ENTRY: 
      return {
        ...state,
        bookCollectionEntries: state.bookCollectionEntries + 1,
      };
    
    default:
      return state;
  }
} 

export default dataReducer;