const categoryObjectsStylesheet = (props) => {
  return(
    {
      categoryList: {
        height: '61.11vmin',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        overflow: 'auto',
        // paddingTop: '2.78vmin',
        paddingTop: '6.25vmin',
        textAlign: 'center',
        '&::-webkit-scrollbar': {
          width: '0px !important',
        },
      },
      tabEnd: {
        width: '3vmin',
        height: '100%',
      },
      tabRightShadow: props=> ({
        position: 'absolute',
        right: '6.67vmin',
        top: '13.33vmin',
        bottom: '6.67vmin',
        boxShadow: `0 4vmin 2vmin 4vmin ${props.colors.background}`,
      }),
    }
)}
export default categoryObjectsStylesheet;