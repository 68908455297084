import { publicImagesPath } from './path'
/* 
Object = {
  ...category_names, 
  category_name: [ 
    ...paragraphs_list,
    {
      text: {
        type: "bold"/"normal",
        content: @content,
      },
      (optional)image: {
        float: "left"/"right",
        url: @url,
      }
    },
  ],
}
*/

/* Example texts */
const ShortLoremIpsum = `Lorem ipsum dolor sit amet, consectetur 
adipiscing elit. Proin nibh augue, suscipit a, 
scelerisque sed, lacinia in, mi. Cras vel lorem.`;
const LoremIpsum = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nibh augue, suscipit a, scelerisque sed, lacinia in, mi. Cras vel lorem. Etiam pellentesque aliquet tellus. Phasellus pharetra nulla ac diam. Quisque semper justo at risus. Donec venenatis, turpis vel hendrerit interdum, dui ligula ultricies purus, sed posuere libero dui id orci. Nam congue, pede vitae dapibus aliquet, elit magna vulputate arcu, vel tempus metus leo non est. Etiam sit amet lectus quis est congue mollis. Phasellus congue lacus eget neque. Phasellus ornare, ante vitae consectetuer consequat, purus sapien ultricies dolor, et mollis pede metus eget nisi. Praesent sodales velit quis augue. Cras suscipit, urna at aliquam rhoncus, urna quam viverra nisi, in interdum massa nibh nec erat.";
const LangShortLorem = {
  "0": "En "+ShortLoremIpsum,
  "1": "Pl "+ShortLoremIpsum,
  "2": "De "+ShortLoremIpsum,
  "3": "Pусский бмдящюйць "+ShortLoremIpsum,
  "4": "Украинский бмдящюйць "+ShortLoremIpsum,
  "5": "It "+ShortLoremIpsum,
  "6": "Es "+ShortLoremIpsum,
  "7": "Fr "+ShortLoremIpsum,
}


const LangLorem = {
  "0": "En "+LoremIpsum,
  "1": "Pl "+LoremIpsum,
  "2": "De "+LoremIpsum,
  "3": "Pусский бмдящюйць "+LoremIpsum,
  "4": "Украинский бмдящюйць "+LoremIpsum,
  "5": "It "+LoremIpsum,
  "6": "Es "+LoremIpsum,
  "7": "Fr "+LoremIpsum,
}
const EastWingTitle = {
  "0": "East Wing",
  "1": "Skrzydło wschodnie",
  "2": "Ostflügel",
  "3": "Восточное крыло",
  "4": "Східне крило",
  "5": "Ala est",
  "6": "Ala este",
  "7": "Aile est",
}
const SouthWingTitle = {
  "0": "South Wing",
  "1": "Skrzydło południowe",
  "2": "Südflügel",
  "3": "Южное крыло",
  "4": "Південне крило",
  "5": "Ala sud",
  "6": "Ala sur",
  "7": "Aile sud",
}
const WestWingTitle = {
  "0": "West Wing",
  "1": "Skrzydło zachodnie",
  "2": "Westflügel",
  "3": "Западное крыло",
  "4": "Західне крило",
  "5": "Ala ovest",
  "6": "Ala oeste",
  "7": "Aile ouest",
}

const constantTexts = {
  "galleries": [
    { text: { type: "bold", content: LangShortLorem}},
    {
      text: { type: "normal", content: LangLorem },
      image: { float: "left", url: publicImagesPath+"kruzganki1.jpg" }
    },
    { text: { type: "bold", content: EastWingTitle}},
    {
      text: { type: "normal", content: LangLorem },
      image: { float: "right", url: publicImagesPath+"kruzganki2.jpg" }
    },
    { text: { type: "bold", content: SouthWingTitle}},
    {
      text: { type: "normal", content: LangLorem },
      image: { float: "left", url: publicImagesPath+"kruzganki2.jpg" }
    },
    { text: { type: "bold", content: WestWingTitle}},
    {
      text: { type: "normal", content: LangLorem },
      image: { float: "right", url: publicImagesPath+"kruzganki2.jpg" }
    },
  ],
  "basilica": [
    { text: { type: "bold", content: LangShortLorem } },
    {
      text: { type: "normal", content: LangLorem },
      image: { float: "left", url: publicImagesPath+"bazylika1.jpg" }
    },
    {
      text: { type: "normal", content: LangLorem },
    },
    {
      text: { type: "normal", content: LangLorem },
    },
    {
      text: { type: "normal", content: LangLorem },
      image: { float: "right", url: publicImagesPath+"bazylika2.jpg" }
    },
    { text: { type: "normal", content: LangLorem }},
  ]
};
export default constantTexts;